import React, { useState } from 'react';

import styles from './Register.module.scss';
import { userRegister } from '../../../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { phoneNumberSettings } from '../../../../hooks/phoneNumberSettings';
import { siteSettingsGetAll } from '../../../../redux/actions/siteSettingsAction';
import { Helmet } from 'react-helmet';
import Loading from '../../../../components/loading/Loading';
import Kvkk from '../../../KVKK/Kvkk';

const Register = () => {
  const [isSelectedPhoto, setIsSelectedPhoto] = useState(false);
  const [isShowKvkk, setIsShowKvkk] = useState(false);
  const [isKvkk, setIsKvkk] = useState(false);
  const [errorText, setErrorText] = useState('');

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer);
  const error = useSelector((state) => state.errorReducer)?.auth;
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  const actions = {
    register: bindActionCreators(userRegister, dispatch),
    getAllSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  const registerHandler = (e) => {
    e.preventDefault();
    if (!isKvkk) {
      setErrorText(
        'KVKK Metnini kabul etmelisiniz. İşleminize devam etmek için en alttaki kutucuğu onaylayın.',
      );
      return;
    }
    const form = document.querySelector('#regForm');
    const formData = new FormData(form);

    actions.register(formData);
    actions.getAllSiteSettings();
  };

  return (
    <>
      <div className={styles.errors}>
        {errorText && <p>{errorText}</p>}
        {error && <p>{error}</p>}
      </div>

      <Helmet>
        <title>{'Kayıt Ol - ' + siteSettings.title || 'Nakliyecim.net'}</title>
        <meta name='description' content={siteSettings.description} />
        <meta name='keywords' content={siteSettings.keywords} />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/auth' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <form id='regForm' className={styles['register-form']}>
        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='fullname'>
            Ad & Soyad *
          </label>
          <input
            type='text'
            id='fullname'
            placeholder='Ad ve Soyad'
            name='fullname'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='email'>
            Email Adresi *
          </label>
          <input
            type='email'
            id='email'
            placeholder='Email Adresini giriniz.'
            name='email'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='username'>
            Kullanıcı Adı *
          </label>
          <input
            type='text'
            id='username'
            placeholder='Kullanıcı Adı girin'
            name='username'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='address'>
            Adres Bilgileriniz *
          </label>
          <input
            type='text'
            id='address'
            placeholder='Açık adres bilgisi'
            name='address'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='postcode'>
            Posta Kodu *
          </label>
          <input
            type='number'
            id='postcode'
            placeholder='Posta Kodu'
            name='postcode'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='phone'>
            Cep Telefonu *
          </label>
          <input
            onChange={phoneNumberSettings}
            type='number'
            id='phone'
            placeholder='Cep Telefonu'
            name='phone'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='gender'>
            Cinsiyet Bilginiz *
          </label>
          <select
            defaultValue=''
            name='gender'
            className={styles['register-form__control__input']}
            id='gender'
            required>
            <option value='' disabled>
              Bir Cinsiyet Seçiniz...
            </option>
            <option value='1'>Erkek</option>
            <option value='2'>Kadın</option>
          </select>
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='password'>
            Parola *:
          </label>
          <input
            type='password'
            id='password'
            placeholder='Parola Giriniz.'
            name='password'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label className={styles['register-form__control__label']} htmlFor='rePassword'>
            Parola Tekrar *:
          </label>
          <input
            type='password'
            id='rePassword'
            placeholder='Parola tekrarı'
            name='rePassword'
            className={styles['register-form__control__input']}
            required
          />
        </div>

        <div className={styles['register-form__control']}>
          <label
            style={{ color: isSelectedPhoto && 'yellowgreen' }}
            className={styles['register-form__control__files-label']}
            htmlFor='profileImg'>
            {isSelectedPhoto ? 'Resim Seçtiniz.' : 'Profil Fotoğrafı Yükle'}
          </label>
          <input
            onChange={(e) =>
              e.target.files.length > 0 ? setIsSelectedPhoto(true) : setIsSelectedPhoto(false)
            }
            hidden
            type='file'
            id='profileImg'
            name='profileImg'
          />
        </div>

        <div className={styles['register-form__kvkk']}>
          <input
            onChange={(e) => {
              setIsKvkk(e.target.checked);
              if (e.target.checked) {
                setErrorText('');
              }
            }}
            required
            type='checkbox'
            id='kvkk'
            name='kvkk'
          />
          <span onClick={() => setIsShowKvkk(true)} className={styles['register-form__kvkk__btn']}>
            Kvkk
          </span>
          {isShowKvkk && (
            <>
              <div
                onClick={() => setIsShowKvkk(false)}
                className={styles['register-form__kvkk__text__closer']}></div>
              <div className={styles['register-form__kvkk__text']}>
                <Kvkk />{' '}
              </div>
            </>
          )}
          <label className={styles['register-form__kvkk__label']} htmlFor='kvkk'>
            metnini okudum kabul ediyorum
          </label>
        </div>

        <button className={styles['register-form__button']} onClick={registerHandler}>
          {isLoadingState.auth ? <Loading /> : 'Kayıt Ol'}
        </button>
        {user && user.username && <Navigate to={'/'} />}
      </form>
    </>
  );
};

export default Register;
