import { myAxios } from '../myAxios';
import * as types from '../actionTypes/userTypes';
import { errorAction } from './errorActions';
import { loadingStateChanged } from './loadingStateActions';
import saveMobileDeviceToken from '../../hooks/saveMobileDeviceToken';

export const userGetAllSuccess = (users) => ({
  type: types.USER_GET_ALL_SUCCESS,
  payload: users,
});

export const userGetAll = () => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ userGetAll: true }));

    const response = await myAxios().get('api/user');
    const data = await response.data; // user list

    //loading end
    dispatch(loadingStateChanged({ userGetAll: false }));

    return dispatch(userGetAllSuccess(data.data));
  };
};

export const userLoginSuccess = () => ({
  type: types.USER_LOGİN_SUCCESS,
});

export const userLogin = (formData) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ auth: true }));

      const response = await myAxios().postForm('api/user/login', formData);
      const { data, errors } = await response.data; // {token:xxx.xxx.xxx}

      if (errors) {
        //loading end
        dispatch(loadingStateChanged({ auth: false }));

        return dispatch(errorAction({ auth: errors }));
      }else{
       const userToken = data.token
       const notificationToken = localStorage.getItem("notificationToken")
       if(userToken,notificationToken){
        await saveMobileDeviceToken(userToken,notificationToken)
       }
      }
      localStorage.setItem('token', JSON.stringify(data.token));
      
      //loading end
      dispatch(loadingStateChanged({ auth: false }));

      return dispatch(userLoginSuccess());
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ auth: false }));
    }
  };
};

export const userRegisterSuccess = () => ({
  type: types.USER_REGİSTER_SUCCESS,
});

export const userRegister = (formData) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ auth: true }));

      const response = await myAxios().postForm('api/user/register', formData);
      const { data, errors } = await response.data; // {token:xxx.xxx.xxx}

      if (errors) {
        //loading end
        dispatch(loadingStateChanged({ auth: false }));
        return dispatch(errorAction({ auth: errors }));
      }

      //loading end
      dispatch(loadingStateChanged({ auth: false }));
      localStorage.setItem('token', JSON.stringify(data.token));

      const userToken = data.token
      const notificationToken = localStorage.getItem("notificationToken")
      if(userToken,notificationToken){
        await saveMobileDeviceToken(userToken,notificationToken)
      }
      return dispatch(userLoginSuccess());
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ auth: false }));
    }
  };
};

export const getUserInfoSuccess = (user) => ({
  type: types.USER_GET_INFO_SUCCESS,
  payload: user,
});

export const getUserInfo = () => {
  return async (dispatch) => {
    try {
      const token = () => JSON.parse(localStorage.getItem('token'));
      //loading start
      dispatch(loadingStateChanged({ getProfile: true }));

      const response = await myAxios().post('api/user/info/' + token());
      const data = await response.data; // user ınfo
      //loading end
      dispatch(loadingStateChanged({ getProfile: false }));
      if (!data.data) {
        localStorage.removeItem('token');
        return dispatch(getUserInfoSuccess([]));
      }

      const userToken = token()
      const notificationToken = localStorage.getItem("notificationToken")
      if(userToken,notificationToken){
        await saveMobileDeviceToken(userToken,notificationToken)
      }

      return dispatch(getUserInfoSuccess(data.data));
    } catch (ex) {
      localStorage.removeItem('token');

      //loading end
      dispatch(loadingStateChanged({ getProfile: false }));
    }
  };
};

export const logoutSuccess = () => ({
  type: types.USER_LOGOUT_SUCCESS,
  payload: null,
});

export const logout = () => {
  return async (dispatch) => {
    // await myAxios().post('/api/user/logout');
    localStorage.removeItem('token');
    return dispatch(logoutSuccess());
  };
};

export const ChangeProfileImageSuccess = () => ({
  type: types.USER_CHANGE_PROFİLE_IMAGE_SUCCESS,
  payload: Math.random(),
});

export const ChangeProfileImage = (formData) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ changeProfileImage: true }));

    await myAxios().putForm('api/user/change/image', formData);

    //loading end
    dispatch(loadingStateChanged({ changeProfileImage: false }));
    return await dispatch(ChangeProfileImageSuccess());
  };
};

export const userMakeAdminSuccess = () => ({
  type: types.USER_MAKE_ADMİN_SUCCESS,
  payload: Math.random(),
});

export const userMakeAdmin = (userId) => {
  return async (dispatch) => {
    try {
      //loading start
      await dispatch(loadingStateChanged({ userMakeAdmin: true }));

      await myAxios().putForm('api/user/make/admin/' + userId);

      //loading end
      await dispatch(loadingStateChanged({ userMakeAdmin: 'ok' }));

      return dispatch(userMakeAdminSuccess());
    } catch (ex) {
      dispatch(loadingStateChanged({ userMakeAdmin: false }));
    }
  };
};
