import React, { useEffect, useState } from 'react';

import styles from '../styles.module.css';

import img from '../../../images/no.jpg';
import { bindActionCreators } from 'redux';
import { advertGetById, advertUpdate } from '../../../redux/actions/advertActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getUserInfo } from '../../../redux/actions/userActions';
import { countriesData } from '../../../CitiesAndTowns/countries';
import { phoneNumberSettings } from '../../../hooks/phoneNumberSettings';
import { categoryGetAll } from '../../../redux/actions/categoryActions';
import { getAllCountries } from '../../../redux/actions/geographyActions';
import { Helmet } from 'react-helmet';
import fileControl from '../../../hooks/fileControl';
import { getAllCitiesByFromWhereCountryId, getAllCitiesByToWhereCountryId, getAllFormDataCountries } from '../../../redux/actions/formDataGeographyActions';

const UpdateAdvert = () => {
  const [isRedirect, setIsRedirect] = useState(false);
  const advert = useSelector((state) => state.advertGetByReducer);
  const user = useSelector((state) => state.userReducer);
  const error = useSelector((state) => state.errorReducer)?.advertUpdate;
  const categories = useSelector((state) => state.categoriesReducer);
  const geography = useSelector((state) => state.formDataGeography);

 

  const dispatch = useDispatch();
  const actions = {
    advertGetById: bindActionCreators(advertGetById, dispatch),
    advertUpdate: bindActionCreators(advertUpdate, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getAllCategories: bindActionCreators(categoryGetAll, dispatch),
    getAllCountries: bindActionCreators(getAllFormDataCountries, dispatch),
    getAllCitiesByFromWhereCountryId: bindActionCreators(getAllCitiesByFromWhereCountryId, dispatch),
    getAllCitiesByToWhereCountryId: bindActionCreators(getAllCitiesByToWhereCountryId, dispatch),
  };

  const { id } = useParams();

  useEffect(() => {
    actions.advertGetById(id);
    actions.getUserInfo();
    actions.getAllCategories();
    actions.getAllCountries();
  }, []);

  useEffect(() => {
    if (user) {
      if (user.id != advert.userId) {
        <Navigate to={'/'} />;
      }
    }
  }, [advert, user]);

  useEffect(() => {
    if (advert.userId && user.id && advert.userId != user.id && !user.isAdmin) {
      setIsRedirect(true);
    }
  }, [advert]);

  const [title, setTitle] = useState(advert.title);
  const [phone, setPhone] = useState(advert.phone);
  const [description, setDescription] = useState(advert.description);
  const [fromWhere, setFromWhere] = useState(advert.fromWhere);
  const [fromWhichCity, setFromWhichCity] = useState(advert.fromWhichCity);
  const [toWhere, setToWhere] = useState(advert.toWhere);
  const [toWhichWhere, setToWhichWhere] = useState(advert.toWhichWhere);
  const [productType, setProductType] = useState(advert.productType);
  const [weight, setWeight] = useState(advert.weight);
  const [offerPrice, setOfferPrice] = useState(advert.offerPrice);
  const [image, setImage] = useState(advert.imageUrl);
  const [userId, setUserId] = useState(advert.userId);
  const [base64SelectImage, setBase64SelectImage] = useState('');

 


  const [fromWhereCountryId,setFromWhereCountryId] = useState(0)
  const [toWhereCountryId,setToWhereCountryId] = useState(0)
  const [fromWhereCityId,setFromWhereCityId] = useState(0)
  const [toWhereCityId,setToWhereCityId] = useState(0)

  useEffect(() => {
    setTitle(advert.title);
    setPhone(advert.phone);
    setDescription(advert.description);
    setFromWhere(advert.fromWhere);
    setFromWhichCity(advert.fromWhichCity);
    setToWhere(advert.toWhere);
    setToWhichWhere(advert.toWhichWhere);
    setProductType(advert.productType);
    setWeight(advert.weight);
    setOfferPrice(advert.offerPrice);
    setImage(advert.imageUrl);
    setUserId(advert.userId);


    if(geography.countries && advert.id){
      setFromWhereCountryId(geography.countries.filter(x=>x.name == advert.fromWhere)[0]?.id)
      setToWhereCountryId(geography.countries.filter(x=>x.name == advert.toWhere)[0]?.id)
    }

    
  }, [advert,geography.countries]);

  useEffect(()=>{    
    if(fromWhereCountryId || toWhereCountryId) {
      actions.getAllCitiesByFromWhereCountryId(fromWhereCountryId)
      actions.getAllCitiesByToWhereCountryId(toWhereCountryId)
    }
  },[fromWhereCountryId,toWhereCountryId])

  useEffect(()=>{
    if(geography.fromCities && fromWhereCityId){
      setFromWhichCity(geography.fromCities.filter(x=>x.id == fromWhereCityId)[0].name)
    }
    if(geography.toCities && toWhereCityId) {
      setToWhichWhere(geography.toCities.filter(x=>x.id == toWhereCityId)[0].name)
    }
  },[fromWhereCityId,toWhereCityId])

  const updateAdvertHandler = async (e) => {
    const form = document.querySelector('#updateForm');

    let formData = new FormData(form);
    formData.set("fromWhere",fromWhere)
    formData.set("fromWhichCity",fromWhichCity)
    formData.set("toWhere",toWhere)
    formData.set("toWhichWhere",toWhichWhere)

    actions.advertUpdate(formData);
  };

  const siteSettings = useSelector((state) => state.siteSettingsReducer);


    const selectedFromWhereCountryHandle = (e)=>{
      const selectedValue = e.target[e.target.selectedIndex].innerHTML
      const selectedId = e.target[e.target.selectedIndex].id
      actions.getAllCitiesByFromWhereCountryId(selectedId)
      setFromWhere(selectedValue)
      setFromWhereCountryId(selectedId)
      setFromWhichCity("")
      setFromWhereCityId(0)
    }

    const selectedToWhereCountryHandle = (e)=>{
      const selectedValue = e.target[e.target.selectedIndex].innerHTML
      const selectedId = e.target[e.target.selectedIndex].id
      actions.getAllCitiesByToWhereCountryId(selectedId)
      setToWhere(selectedValue)
      setToWhereCountryId(selectedId)
      setToWhichWhere("")
      setToWhereCityId(0)
    }

    const selectedFromWhereCityHandle = (e)=>{
      const selectedValue = e.target[e.target.selectedIndex].innerHTML
      const selectedId = e.target[e.target.selectedIndex].id
      setFromWhichCity(selectedValue)
      setFromWhereCityId(selectedId)
    }

    const selectedToWhereCityHandle = (e)=>{
      const selectedValue = e.target[e.target.selectedIndex].innerHTML
      const selectedId = e.target[e.target.selectedIndex].id
      setToWhichWhere(selectedValue)
      setToWhereCityId(selectedId)
    }
  return (
    <div className={styles['page']}>
      <Helmet>
        <title>
          {'İlanını Güncelle - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <form id='updateForm' className={styles['form']} encType='multipart/form-data'>
        <h1 className={styles.title}>İlan Paylaş</h1>
        {error && (
          <div className={styles['errors']}>
            <p className={styles['error']}>{error}</p>
          </div>
        )}
        <div className={styles['form-control']}>
          <label className='top' htmlFor='title'>
            İlan Başlığı
          </label>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='text'
            id='title'
            name='title'
            required
          />
        </div>

        <div className={styles['form-control']}>
          <label className='top'  htmlFor='phone'>
            Telefon Numarası
          </label>
          <input
            value={phone}
            onChange={(e) => {
              phoneNumberSettings(e);
              setPhone(e.target.value);
            }}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='text'
            id='phone'
            name='phone'
            required
          />
        </div>

        <div className={styles['two-section']}>
            <div className={styles['form-control']}>
              <label className='top' htmlFor='fromWhere'>Nereden</label>
              <select                
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                value={fromWhere}
                type='number'
                id='fromWhere'
                name='fromWhere'
                onChange={selectedFromWhereCountryHandle}
                required>
                <option value='0' disabled></option>
                {geography.countries.map((country, i) => (
                  <option key={i} id={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles['form-control']}>
              <label className='top' htmlFor='fromWhichCity'>Neresinden</label>
              <select                
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                value={fromWhichCity}
                id='fromWhichCity'
                name='fromWhichCity'
                onChange={selectedFromWhereCityHandle}
                required>
                <option value='' disabled></option>
                {geography.fromCities.map((country, i) => (
                  <option key={i} id={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div  className={styles['two-section']}>
            <div className={styles['form-control']}>
              <label className='top' htmlFor='toWhere'>Nereye</label>
              <select
                onBlur={(e) =>
                  e.target.value.length > 0
                    ? e.target.previousElementSibling.classList.add('top')
                    : e.target.previousElementSibling.classList.remove('top')
                }
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                value={toWhere}
                id='toWhere'
                name='toWhere'
                onChange={selectedToWhereCountryHandle}
                required>
                <option value='' disabled></option>
                {geography.countries.map((country, i) => (
                  <option key={i} id={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles['form-control']}>
              <label className='top' htmlFor='toWhichWhere'>Neresine</label>
              <select               
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                value={toWhichWhere}
                id='toWhichWhere'
                name='toWhichWhere'
                onChange={selectedToWhereCityHandle}
                required>
                <option value='' disabled></option>
                {geography.toCities.map((country, i) => (
                  <option key={i} id={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

        <div className={styles['form-control']}>
          <label className='top' htmlFor='productType'>
            Yük Tipi (Örneğin: Ev Eşyası)
          </label>
          <input
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='text'
            id='productType'
            name='productType'
            required
          />
        </div>

        <div className={styles['form-control']}>
          <label className='top' htmlFor='weight'>
            Ağırlık Giriniz (Örneğin: 1000 "KG Cinsinden hesaplayın")
          </label>
          <input
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            maxLength={9999999999}
            type='number'
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            id='weight'
            name='weight'
            required
          />
        </div>

        <div className={styles['form-control']}>
          <label className='top' htmlFor='offerPrice'>
            Fiyat Bilgisi (Isteğe Bağlı) (TL)
          </label>
          <input
            value={offerPrice}
            onChange={(e) => setOfferPrice(e.target.value)}
            type='number'
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            id='offerPrice'
            name='offerPrice'
            required
          />
        </div>

        <div className={styles['form-control']}>
          <label className='top' htmlFor='category'>
            Kategori seç
          </label>
          <select
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            name='categoryId'>
            <option value={''} disabled></option>

            {categories?.map((category, i) => (
              <option key={i} selected={category.id === advert.categoryId} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles['form-control']}>
          <label className='top' htmlFor='description'>
            İlan Açıklaması
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={10}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='text'
            id='description'
            name='description'
            required></textarea>
        </div>

        <div className={styles['form-control']}>
          <label className={styles['files-label']} htmlFor='image'>
            İlan Fotoğrafını Değiştir
          </label>
          <input
            onChange={(e) => fileControl(e, setBase64SelectImage)}
            style={{ opacity: 0, height: 0 }}
            type='file'
            id='image'
            name='image'
            required
          />
          <img width={100} src={base64SelectImage || image || img} alt='' />
        </div>

        <input type='hidden' name='userId' value={advert.userId} />
        <input type='hidden' name='id' value={id} />
        <input type='hidden' name='oldServerPath' value={advert.serverPath} />
        <input type='hidden' name='oldImageUrl' value={advert.imageUrl} />

        {!advert && <Navigate to={'/'} />}

        <Link onClick={updateAdvertHandler} className={styles['addBtn']}>
          İlanı Güncelle
        </Link>
      </form>
      {isRedirect && <Navigate to={'/'} />}
    </div>
  );
};

export default UpdateAdvert;
