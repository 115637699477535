import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import img from '../../../images/no.jpg';

import styles from './CompanyDetails.module.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  companyGetAll,
  companyGetAuthorById,
  companyGetById,
} from '../../../redux/actions/companyActions';
import { categoryGetById } from '../../../redux/actions/categoryActions';
import { siteSettingsGetAll } from '../../../redux/actions/siteSettingsAction';
import { Helmet } from 'react-helmet';
import {
  getAllCompanyLikes,
  getAllCompanyRatings,
  likeToCompany,
  ratingToCompany,
  unlikeToCompany,
  unratingToCompany,
} from '../../../redux/actions/companyRatingActions';
import { getUserInfo } from '../../../redux/actions/userActions';
import { loadingStateChanged } from '../../../redux/actions/loadingStateActions';
import Loading from '../../../components/loading/Loading';
import { createNewChat } from '../../../redux/actions/chatActions';

const CompanyDetails = () => {
  const dispatch = useDispatch();
  const companyRatings = useSelector((state) => state.companyRatingReducer);
  const companyLikes = useSelector((state) => state.companyLikeReducer);
  const user = useSelector((state) => state.userReducer);
  const currentCompany = useSelector((state) => state.companyGetByReducer);
  const currentCategory = useSelector((state) => state.currentCategory);
  const companyAuthor = useSelector((state) => state.companyAuthorReducer);
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const createChatState = useSelector((state) => state.createChat);
  const actions = {
    getById: bindActionCreators(companyGetById, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getAllCompany: bindActionCreators(companyGetAll, dispatch),
    getByIdFromCategory: bindActionCreators(categoryGetById, dispatch),
    getAuthorById: bindActionCreators(companyGetAuthorById, dispatch),
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
    getAllCompanyRating: bindActionCreators(getAllCompanyRatings, dispatch),
    getAllCompanyLike: bindActionCreators(getAllCompanyLikes, dispatch),
    likeToCompany: bindActionCreators(likeToCompany, dispatch),
    unlikeToCompany: bindActionCreators(unlikeToCompany, dispatch),
    ratingToCompany: bindActionCreators(ratingToCompany, dispatch),
    unratingToCompany: bindActionCreators(unratingToCompany, dispatch),
    createNewChat: bindActionCreators(createNewChat, dispatch),
  };

  const { id: companyId } = useParams();

  useEffect(() => {
    if (currentCompany.id) {
      actions.getAuthorById(currentCompany.userId);
      actions.getByIdFromCategory(currentCompany.categoryId);
      actions.getAllCompanyRating(currentCompany.id);
      actions.getAllCompanyLike(currentCompany.id);
    }
  }, [currentCompany]);

  useEffect(() => {
    actions.getById(companyId);
    actions.getSiteSettings();
    actions.getUserInfo();
  }, []);

  useEffect(() => {
    if (isLoadingState.companyLike === 'ok') {
      actions.getAllCompanyLike(currentCompany.id);
      dispatch(loadingStateChanged({ companyLike: false }));
    }
  }, [isLoadingState]);

  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  return (
    <div className={styles.page}>
      <Helmet>
        <title>
          {'Firma Detayları - ' +
            (currentCompany.name || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      {isLoadingState.getByIdCompany ? (
        <Loading />
      ) : (
        <div className={styles.details}>
          <div className={styles['details-head']}>
            <h1 className={styles['details-title']}>{currentCompany.name}</h1>
            <header className={styles['details-header']}>
              <p>
                <span>
                  <i className='fa-solid fa-user'></i>
                </span>{' '}
                <span>
                  {isLoadingState.getByCompanyUser ? <Loading /> : companyAuthor.fullname}
                </span>
              </p>
              {user.username && (
                <div className={styles['rating-box']}>
                  {companyLikes?.filter((x) => user.id !== 0 && x.userId === user.id)[0] ? (
                    <span
                      onMouseDown={() => {
                        actions.unlikeToCompany(companyId, user.id);
                      }}
                      className={styles['rating-item']}>
                      {isLoadingState.companyLike ? (
                        <Loading />
                      ) : (
                        <>
                          {companyLikes.length}
                          &nbsp;Beğendin
                        </>
                      )}
                    </span>
                  ) : (
                    <span
                      onMouseDown={() => {
                        actions.likeToCompany(companyId, user.id);
                      }}
                      onMouseUp={() => {
                        actions.getAllCompanyLike(companyId);
                      }}
                      className={styles['rating-item']}>
                      {isLoadingState.companyLike ? (
                        <Loading />
                      ) : (
                        <>
                          {companyLikes.length}
                          &nbsp;Beğen
                        </>
                      )}
                    </span>
                  )}

                  {companyRatings?.filter((x) => user.id && x.userId === user.id)[0] == null && (
                    <span
                      onMouseDown={() => {
                        actions.ratingToCompany(companyId, user.id, 5);
                      }}
                      onMouseUp={() => {
                        actions.getAllCompanyRating(companyId);
                      }}
                      className={styles['rating-item']}>
                      5 Puan ver
                    </span>
                  )}
                </div>
              )}
            </header>
          </div>
          <section className={styles['details-box']}>
            <div className={styles['details-features']}>
              <p className={styles['feature']}>
                <span>Firma No: </span>
                <span>{currentCompany.id}</span>
              </p>
              <p className={styles['feature']}>
                <span>Şehir: </span>
                <span>
                  {currentCompany.city}
                  {currentCompany.town ? '/' + currentCompany.town : ''}
                </span>
              </p>
              <p className={styles['feature']}>
                <span>Firma Adresi: </span>
                <span>{currentCompany.address}</span>
              </p>
              <p className={styles['feature']}>
                <span>Firma Tipi:</span>
                <span>{currentCategory?.name}</span>
              </p>
            </div>
            <div className={styles['details-profile']}>
              <img src={currentCompany.image || img} width='100%' alt='' />
              <div className={styles['details-profile-info']}>
                <span>
                  {isLoadingState.getByCompanyUser ? <Loading /> : companyAuthor.fullname}
                </span>
                <hr />
                <span>
                  <i className='fa-solid fa-phone'></i> {currentCompany.phone}
                </span>
                {user.id != companyAuthor.id && user?.username && (
                  <span>
                    <p
                      onClick={() =>
                        user.id &&
                        companyAuthor.id &&
                        actions.createNewChat([user.id, companyAuthor.id])
                      }
                      className={styles['send-message']}>
                      {isLoadingState.createNewChat ? (
                        <Loading />
                      ) : (
                        <span>
                          <i className='fa-solid fa-message'></i> Mesaj Gönder
                        </span>
                      )}
                    </p>
                  </span>
                )}
              </div>
            </div>

            {/* Description */}
            <section className={styles['details-description']}>
              <span className={styles['section-title']}>Firma Açıklaması</span>
              {currentCompany.description}
            </section>
          </section>
        </div>
      )}

      {createChatState[1] && <Navigate to={'/chats'} />}
    </div>
  );
};

export default CompanyDetails;
