import { myAxios } from '../myAxios';
import * as types from '../actionTypes/siteSettingsTypes';

// get panding update companies
export const siteSettingsGetAllSuccess = (data) => ({
  type: types.SITE_SETTINGS_GET_ALL_SUCCESS,
  payload: data,
});

export const siteSettingsGetAll = () => {
  return async (dispatch) => {
    try {
      const res = await myAxios().get('api/siteSettings');
      const data = await res.data;

      return dispatch(siteSettingsGetAllSuccess(data.data));
    } catch (ex) {}
  };
};

// get panding update companies
export const siteSettingsUpdateSuccess = (data) => ({
  type: types.SITE_SETTINGS_GET_ALL_SUCCESS,
  payload: data,
});

export const siteSettingsUpdate = (formData) => {
  return async (dispatch) => {
    await myAxios().putForm('api/siteSettings', formData);
    const res = await myAxios().get('api/siteSettings');
    const data = await res.data;

    return dispatch(siteSettingsUpdateSuccess(data.data));
  };
};
