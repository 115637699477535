import * as types from '../actionTypes/chatTypes';
import * as initialState from '../initialStates';

export default (state = initialState.chatsState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_MY_CHAT:
      return payload;

    case types.MESSAGE_ADD_SUCCESS:
      let newState = state.map((chat) => {
        if (payload.chatId == chat.id) {
          chat.messages.push(payload);
        }
        return chat;
      });
      return newState;

    default:
      return state;
  }
};
