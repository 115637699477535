import { useEffect, useRef, useState } from 'react'
import images from '../../images'
import styles from './SearchBox.module.scss'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import FilterItem from './modules/filter-item';

const SearchBox = () => {

  const [text, setText] = useState('');
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isMicrophone,setIsMicrophone] = useState(false)

  const handleListen = () => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({ continuous: true, language:"tr" });
      setIsMicrophone(true)
    } else {
      alert('Tarayıcınız mikrofonu desteklemiyor.');
    }
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setText(transcript.replaceAll(/[^a-zA-Z0-9ğüşıöçĞÜŞİÖÇ\s]/g, ''));
    setIsMicrophone(false)
    resetTranscript();
  };

  useEffect(()=>{
    if(transcript){
      setText(transcript)
    }
  },[transcript])

  const [searchType,setSearchType] = useState("company")

  return (
    <div className={styles["search-box"]}>      
      <header className={styles["search-box__header"]}>

        <p className={styles["search-box__search-label"]}>Ne Arıyorsun ?</p>
        <div className={styles["search-box__header__types"]}>
          <div onClick={()=>setSearchType("company")} data-active={searchType == "company"} className={styles["search-box__header__types__type"]}>
            Firma
          </div>
          <div onClick={()=>setSearchType("advert")} data-active={searchType == "advert"} className={styles["search-box__header__types__type"]}>
            Yük İlanı
          </div>
        </div>
        <div  className={styles["search-box__header__search"]}>
          <img src={images.searchIcon} className={styles["search-box__header__search__icon"]} />
          <input onClick={()=>isMicrophone && stopListening()} value={text} onChange={(e)=>setText(e.target.value)}  className={styles["search-box__header__search__input"]} placeholder='sizin için en uygun yükü veya nakliye firmasını arayın'/>
          <button onClick={()=>{
            if(isMicrophone) {
              stopListening()
            }else {
              handleListen()
            }
          }} className={styles["search-box__header__search__button"]}>
          <img src={isMicrophone ? images.microphoneIconActive:images.microphoneIcon}/>
          </button>
        </div>

        <button onClick={()=>isMicrophone && stopListening()} className={styles["search-box__search-btn"]}>ARA</button>


      
        <p className={styles["search-box__search-label"]}>Detaylı arama yapmak için filtreleri kullanabilirsiniz</p>
        <div className={styles["search-box__filters"]}>
          {
            searchType == "advert" && (
              <>
                <FilterItem {...{
                  icon:images.transportationRight,
                  title:"Nereden",
                  description:"Ankara",
                  sub:"Keçiören",
                  popupChildren:(
                    <div>
                      Nereden Filtresi
                    </div>
                  )
                }}/>

                <FilterItem {...{
                    icon:images.transportationLeft,
                    title:"Nereye",
                    description:"Ankara",
                    sub:"Keçiören",
                    popupChildren:(
                      <div>
                        Nereye Filtresi
                      </div>
                    )
                }}/>       

                <FilterItem {...{
                    icon:images.transportationLeft,
                    title:"Kategori",
                    description:"Evden Eve nakliyat",
                    sub:"Kategori Ara",
                    popupChildren:(
                      <div>
                        Kategori Filtresi
                      </div>
                    )
                }}/>
              </>
            )
          }

          {
            searchType == "company" && (
              <>
                <FilterItem {...{
                  icon:images.transportationRight,
                  title:"Ülke",
                  description:"Türkiye",
                  sub:"Hangi Ülke",
                  popupChildren:(
                    <div>
                      Ülke Filtresi
                    </div>
                  )
                }}/>

                <FilterItem {...{
                  icon:images.transportationRight,
                  title:"İl/İlçe",
                  description:"Ankara",
                  sub:"Keçiören",
                  popupChildren:(
                    <div>
                      İl / İlçe Filtresi
                    </div>
                  )
                }}/> 

                <FilterItem {...{
                    icon:images.transportationLeft,
                    title:"Kategori",
                    description:"Evden Eve nakliyat",
                    sub:"Kategori Ara",
                    popupChildren:(
                      <div>
                        Kategori Filtresi
                      </div>
                    )
                }}/>
              </>
            )
          }
        </div>
        
      </header>
    </div>
  )
}

export default SearchBox
