import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  advertGetAll,
  advertGetAuthorById,
  advertGetById,
} from '../../../redux/actions/advertActions';

import img from '../../../images/no.jpg';

import styles from './AdvertDetails.module.css';
import { Navigate, useParams } from 'react-router-dom';
import { loadingStateChanged } from '../../../redux/actions/loadingStateActions';
import { categoryGetAll } from '../../../redux/actions/categoryActions';
import Loading from '../../../components/loading/Loading';
import { Helmet } from 'react-helmet';
import { siteSettingsGetAll } from '../../../redux/actions/siteSettingsAction';
import { createNewChat } from '../../../redux/actions/chatActions';

const AdvertDetails = () => {
  const dispatch = useDispatch();
  const currentAdvert = useSelector((state) => state.advertGetByReducer);
  const advertAuthorInfo = useSelector((state) => state.advertAuthorReducer);
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const categories = useSelector((state) => state.categoriesReducer);
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const user = useSelector((state) => state.userReducer);
  const createChatState = useSelector((state) => state.createChat);

  const [isNotFound, setIsNotFound] = useState(false);

  const actions = {
    getById: bindActionCreators(advertGetById, dispatch),
    getAll: bindActionCreators(advertGetAll, dispatch),
    getAuthorById: bindActionCreators(advertGetAuthorById, dispatch),
    getAllCategories: bindActionCreators(categoryGetAll, dispatch),
    getAllSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
    createNewChat: bindActionCreators(createNewChat, dispatch),
  };

  const { id } = useParams();

  useEffect(() => {
    if (currentAdvert.id) {
      actions.getAuthorById(currentAdvert.userId);
    }
  }, [currentAdvert]);

  useEffect(() => {
    actions.getById(id);
    actions.getAllCategories();
    actions.getAllSiteSettings();
  }, []);

  useEffect(() => {
    if (isLoadingState.advertGetById === 'no') {
      dispatch(loadingStateChanged({ advertGetById: false }));
      setIsNotFound(true);
    }
  }, [isLoadingState.advertGetById]);

  return (
    <div className={styles.page}>
      <Helmet>
        <title>
          {'İlan Detayı - ' +
            (currentAdvert.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      {!isNotFound ? (
        <>
          {isLoadingState.advertGetById === true ? (
            <Loading />
          ) : (
            <div className={styles.details}>
              <h1 className={styles['details-title']}>{currentAdvert.title}</h1>
              <header className={styles['details-header']}>
                <p className={styles['fromwhere']}>
                  <span>Nereden: </span> {currentAdvert.fromWhere}/{currentAdvert.fromWhichCity}
                </p>
                <p className={styles['fromwhere']}>
                  <span>Nereye: </span> {currentAdvert.toWhere}/{currentAdvert.toWhichWhere}
                </p>
              </header>
              <section className={styles['details-box']}>
                <div className={styles['details-features']}>
                  <p className={styles['feature']}>
                    <span>İlan No: </span>
                    <span>{currentAdvert.id}</span>
                  </p>
                  <p className={styles['feature']}>
                    <span>Ağırlık (Kg): </span>
                    <span>
                      {currentAdvert.weight > 0
                        ? currentAdvert.weight + ' KG'
                        : 'Ağırlık Bilgisi Yok'}{' '}
                    </span>
                  </p>
                  <p className={styles['feature']}>
                    <span>Yük Tipi: </span>
                    <span>{currentAdvert.productType}</span>
                  </p>
                  <p className={styles['feature']}>
                    <span>Fiyat Teklifi:</span>
                    <span>
                      {currentAdvert.offerPrice > 0
                        ? currentAdvert.offerPrice + ' TL'
                        : 'Fiyat Bilgisi Yok'}
                    </span>
                  </p>
                  <p className={styles['feature']}>
                    <span>Kategori:</span>
                    <span>
                      {categories?.filter((x) => x.id === currentAdvert.categoryId)[0]?.name}
                    </span>
                  </p>
                </div>

                {/* Description */}
                <section className={styles['details-description']}>
                  <span className={styles['section-title']}>ilan Açıklaması</span>
                  {currentAdvert.description}
                </section>

                <div className={styles['details-profile']}>
                  <img src={currentAdvert.imageUrl || img} width='100%' alt='' />
                  <div className={styles['details-profile-info']}>
                    <span>{advertAuthorInfo.fullname}</span>
                    <hr />
                    <span>
                      <i className='fa-solid fa-phone'></i> {currentAdvert.phone}
                    </span>

                    {advertAuthorInfo.id != user.id && user?.username && (
                      <span
                        onClick={() => actions.createNewChat([user.id, advertAuthorInfo.id])}
                        className={styles['send-message']}>
                        {isLoadingState.createNewChat ? (
                          <Loading />
                        ) : (
                          <>
                            <i className='fa-solid fa-message'></i> Mesaj Gönder
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      ) : (
        <div>İlan'a ulaşımalıyor.</div>
      )}
      {createChatState[1] && <Navigate to={'/chats'} />}
    </div>
  );
};

export default AdvertDetails;
