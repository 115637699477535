import styles from './FilterPopup.module.scss'

const FilterPopup = ({children,setShow}) => {  
  return (
    <>
      <div className={styles["popup"]}>
          {children}
      </div>
      <div onClick={()=>setShow(false)}  className={styles["popup__close"]}></div>
    </>
  )
}

export default FilterPopup
