import * as types from '../actionTypes/advertTypes';

import { myAxios } from '../myAxios';
import { errorAction } from './errorActions';
import { loadingStateChanged } from './loadingStateActions';

export const advertGetAllSuccess = (data) => ({
  type: types.ADVERT_GETALL_SUCCESS,
  payload: data,
});

export const advertGetAll = () => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ advertList: true }));

    const response = await myAxios().get('api/advert');
    const data = await response.data;

    //loading end
    dispatch(loadingStateChanged({ advertList: false }));

    return dispatch(advertGetAllSuccess(data.data));
  };
};

export const advertGetByIdSuccess = (data) => ({
  type: types.ADVERT_GET_BY_ID_SUCCESS,
  payload: data,
});

export const advertGetById = (id) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ advertGetById: true }));

      const response = await myAxios().get('api/advert/' + id);
      const data = await response.data;
      if (!data.data) {
        //loading end
        dispatch(loadingStateChanged({ advertGetById: 'no' }));
      }
      //loading end
      dispatch(loadingStateChanged({ advertGetById: false }));

      return dispatch(advertGetByIdSuccess(data.data));
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ advertGetById: 'no' }));
    }
  };
};

export const advertGetMySuccess = (data) => ({
  type: types.ADVERT_GET_BY_ID_SUCCESS,
  payload: data,
});

export const advertGetMy = (userId) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ advertGetById: true }));

    const response = await myAxios().get('api/advert/my/' + userId);
    const data = await response.data;

    //loading end
    dispatch(loadingStateChanged({ advertGetById: false }));

    dispatch(advertGetMySuccess(data.data));
  };
};

export const advertCreateSuccess = () => ({
  type: types.ADVERT_ADD_SUCCESS,
});

export const advertCreate = (formData) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ advertAdded: true }));

      await myAxios().postForm('api/advert', formData);

      //loading end
      dispatch(loadingStateChanged({ advertAdded: 'ok' }));

      return dispatch(advertCreateSuccess());
    } catch (ex) {
      var error = ex.response.data.errors;

      //loading end
      dispatch(loadingStateChanged({ advertAdded: false }));

      return dispatch(errorAction({ advertCreate: error }));
    }
  };
};

export const advertDeleteSuccess = () => ({
  type: types.ADVERT_DELETE_SUCCESS,
});

export const advertDelete = (id) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ advertDelete: true }));

    await myAxios().delete('api/advert/' + id);

    //loading end
    dispatch(loadingStateChanged({ advertDelete: 'ok' }));

    return dispatch(advertDeleteSuccess());
  };
};

export const advertUpdateSuccess = (data) => ({
  type: types.ADVERT_UPDATE_SUCCESS,
  payload: data,
});

export const advertUpdate = (formData) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ advertUpdate: true }));

      await myAxios().putForm('api/advert', formData);

      //loading end
      dispatch(loadingStateChanged({ advertUpdate: 'ok' }));

      return dispatch(advertUpdateSuccess(Math.random() + 1 * 100));
    } catch (ex) {
      var errors = ex.response.data.errors;
      //loading end
      dispatch(loadingStateChanged({ advertUpdate: false }));

      return dispatch(errorAction({ advertUpdate: errors }));
    }
  };
};

export const advertGetAuthorByIdSuccess = (data) => ({
  type: types.ADVERT_GET_AUTHOR_BY_ID,
  payload: data,
});

export const advertGetAuthorById = (userId) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ getByAdvertUser: true }));

    const response = await myAxios().get('api/user/' + userId);
    const data = await response.data;

    //loading end
    dispatch(loadingStateChanged({ getByAdvertUser: false }));

    return dispatch(advertGetAuthorByIdSuccess(data.data));
  };
};
