import * as types from '../actionTypes/companyTypes';

import { myAxios } from '../myAxios';

import { errorAction } from './errorActions';
import { loadingStateChanged } from './loadingStateActions';

export const companyGetAllSuccess = (data) => ({
  type: types.COMPANY_GETALL_SUCCESS,
  payload: data,
});

export const companyGetAll = (isSuccess = true) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ companyList: true }));
      const res = await myAxios().get('api/Company/success/' + isSuccess);

      const data = await res.data;
      //loading end
      dispatch(loadingStateChanged({ companyList: false }));

      return dispatch(companyGetAllSuccess(data.data));
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ companyList: false }));
    }
  };
};

export const companyGetByIdSuccess = (data) => ({
  type: types.COMPANY_GET_BY_ID_SUCCESS,
  payload: data,
});

export const companyGetById = (id) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ getByIdCompany: true }));

    const res = await myAxios().get('api/Company/' + id);
    const data = await res.data;

    if (!data) {
      return false;
    }

    //loading end
    dispatch(loadingStateChanged({ getByIdCompany: false }));

    return dispatch(companyGetByIdSuccess(data.data));
  };
};

export const companyGetMySuccess = (data) => ({
  type: types.COMPANY_GET_MY_SUCCESS,
  payload: data,
});

export const companyGetMy = (userId) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ getMyCompanies: true }));

    const res = await myAxios().get('api/Company/my/' + userId);
    const data = await res.data;

    //loading end
    dispatch(loadingStateChanged({ getMyCompanies: false }));

    return dispatch(companyGetMySuccess(data.data));
  };
};

export const companyIsSuccessSuccess = (data) => ({
  type: types.COMPANY_SUCCESS_SUCCESS,
  payload: data,
});

export const companyIsSuccess = (id) => {
  return async (dispatch) => {
    const res = await myAxios().post('api/Company/success/active/' + id);
    await dispatch(companyIsSuccessSuccess(id));
  };
};

export const companyIsFailedSuccess = (data) => ({
  type: types.COMPANY_FAİLED_SUCCESS,
  payload: data,
});

export const companyIsFailed = (id, failedMessage) => {
  return async (dispatch) => {
    const res = await myAxios().put('api/Company/failed/' + id + '/' + failedMessage);
    await dispatch(companyIsFailedSuccess(id));
  };
};

export const companyGetAuthorByIdSuccess = (data) => ({
  type: types.COMPANY_GET_AUTHOR_BY_ID,
  payload: data,
});

export const companyGetAuthorById = (userId) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ getByCompanyUser: true }));

    const response = await myAxios().get('api/user/' + userId);
    const data = await response.data;
    //loading end
    dispatch(loadingStateChanged({ getByCompanyUser: false }));

    return dispatch(companyGetAuthorByIdSuccess(data.data));
  };
};

export const companyAddSuccess = (data) => ({
  type: types.COMPANY_ADD_SUCCESS,
  payload: data,
});

export const companyAdd = (formData) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ companyAdded: true }));

      await myAxios().postForm('api/Company', formData);

      //loading end
      dispatch(loadingStateChanged({ companyAdded: 'ok' }));

      return dispatch(companyAddSuccess(formData));
    } catch (ex) {
      var errors = ex.response.data.errors;

      //loading end
      dispatch(loadingStateChanged({ companyAdded: false }));
      dispatch(errorAction({ companyAdded: errors }));
    }
  };
};

// request Company Update
export const requestCompanyUpdateSuccess = (data) => ({
  type: types.COMPANY_REQUEST_UPDATE_SUCCESS,
  payload: data,
});

export const requestCompanyUpdate = (formData) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ companyUpdate: true }));
    const res = await myAxios().postForm('api/Company/request/update', formData);
    var data = await res.data;
    try {
      //loading end
      dispatch(loadingStateChanged({ companyUpdate: 'ok' }));
      return dispatch(requestCompanyUpdateSuccess(formData));
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ companyUpdate: false }));
      await dispatch(errorAction({ companyUpdate: data.errors }));
    }
  };
};

// get panding update companies
export const companyGetPendingUpdateSuccess = (data) => ({
  type: types.COMPANY_GET_PENDİNG_UPDATE_SUCCESS,
  payload: data,
});

export const companyGetPendingUpdate = () => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ companyList: true }));

    const res = await myAxios().get('api/Company/pendings');
    const data = await res.data;

    //loading end
    dispatch(loadingStateChanged({ companyList: false }));

    return dispatch(companyGetPendingUpdateSuccess(data.data));
  };
};

// get panding update companies
export const companyGetPendingUpdateByIdSuccess = (data) => ({
  type: types.COMPANY_GET_BY_ID_PENDİNG_UPDATE_SUCCESS,
  payload: data,
});

export const companyGetPendingUpdateById = (companyId) => {
  return async (dispatch) => {
    const res = await myAxios().get('api/Company/my/pending/' + companyId);
    const data = await res.data;

    return dispatch(companyGetPendingUpdateByIdSuccess(data.data));
  };
};

// confirm pending update
export const companyConfirmPendingUpdateSuccess = (id) => ({
  type: types.COMPANY_CONFİRM_PENDİNG_UPDATE_SUCCESS,
  payload: id,
});

export const companyConfirmPendingUpdate = (id) => {
  return async (dispatch) => {
    await myAxios().put('api/Company/pending/active/' + id);

    return dispatch(companyConfirmPendingUpdateSuccess(id));
  };
};

//Delete company
export const companyDeleteSuccess = (id) => ({
  type: types.COMPANY_DELETE_SUCCESS,
  payload: id,
});

export const companyDelete = (id) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ companyDelete: true }));

    await myAxios().delete('api/Company/' + id);

    //loading end
    dispatch(loadingStateChanged({ companyDelete: false }));

    return dispatch(companyDeleteSuccess(id));
  };
};
