import React, { useState } from "react";

import styles from "./AdminList.module.css";
import Pagenations from "../../pagenations/Pagenations";

const selectedday = [2, 4];

selectedday.forEach((element) => {});

const AdminList = ({ children, theads, title }) => {
   const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <>
      <h1 className={styles["table-title"]}>{title}</h1>
      <table className={styles["admin-list"]}>
        <thead className={styles["admin-list-heads"]}>
          <tr>
            {theads.map((head, i) => (
              <th className={styles["admin-list-heads"]} key={i}>
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles["admin-list-body"]}>
          {children.slice(startIndex, endIndex)}
        </tbody>
      </table>
      <Pagenations
        pageArray={children}
        showElementLength={5}
        setEnd={setEndIndex}
        setStart={setStartIndex}
        scrollTo={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default AdminList;
