import { useState } from 'react'
import images from '../../../../images'
import FilterPopup from '../filter-popup'
import styles from './FilterItem.module.scss'

const FilterItem = ({icon="",title,description,sub="",popupChildren}) => {
  const [isShowPopup,setIsShowPopup] = useState(false)
  return (
    <>
      <div onClick={()=>setIsShowPopup(true)} className={styles["filter-item"]}>
        {icon && <img className={styles["filter-item__icon"]} src={icon} alt='nereden nereye icon'/>}
        <p className={styles["filter-item__title"]}>{title}</p>
        <h5 className={styles["filter-item__description"]}>{description}</h5>
        {sub && <p className={styles["filter-item__sub"]}>{sub}</p>}

      </div>
      {isShowPopup && <FilterPopup setShow={setIsShowPopup}>{popupChildren}</FilterPopup>}
    </>
  )
}

export default FilterItem
