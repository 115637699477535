import * as actionTypes from "../actionTypes/companyTypes";
import * as initialStates from "../initialStates";

export const companyGetByReducer = (
  state = initialStates.companyGetByState,
  action
) => {
  switch (action.type) {
    case actionTypes.COMPANY_GET_BY_ID_SUCCESS:
      return action.payload;
    case actionTypes.COMPANY_GET_BY_NAME_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};
