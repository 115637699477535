import React from 'react'

import styles from './AdminListItem.module.css'

const AdminListItem = ({ items }) => {
    return (
        <tr className={styles.tr}>
            {items.map((td, i) => <td className={styles.td} key={i}> {td} </td>)}
        </tr>
    )
}

export default AdminListItem
