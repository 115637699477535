import * as types from '../actionTypes/companyTypes'
import * as initialState from '../initialStates'

export const getByIdPendingUpdateReducer = (state = initialState.companyGetByState, action) => {
    switch (action.type) {

        case types.COMPANY_GET_BY_ID_PENDİNG_UPDATE_SUCCESS:
            return action.payload
        default:
            return state;
    }
}