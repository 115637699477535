import * as types from '../actionTypes/geographyActionTypes';
import { myAxios } from '../myAxios';

export const getAllCountriesSuccess = (data) => ({
  type: types.GET_ALL_COUNTRIES_SUCCESS,
  payload: data,
});

export const getAllCountries = () => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography');
    var data = await response.data;
    dispatch(getAllCountriesSuccess(data.data));
  };
};

export const getAllCitiesByCountryIdSuccess = (data) => ({
  type: types.GET_ALL_CITIES_BY_COUNTRY_ID_SUCCESS,
  payload: data,
});

export const getAllCitiesByCountryId = (countryId) => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography/city/' + countryId);
    var data = await response.data;
    dispatch(getAllCitiesByCountryIdSuccess(data.data));
  };
};

export const getAllTonwsByCityIdSuccess = (data) => ({
  type: types.GET_ALL_TOWNS_BY_CITY_ID_SUCCESS,
  payload: data,
});

export const getAllTonwsByCityId = (cityId) => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography/town/' + cityId);
    var data = await response.data;
    dispatch(getAllTonwsByCityIdSuccess(data.data));
  };
};
