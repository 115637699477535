import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import styles from "./Navbar.module.scss";

import { useEffect } from "react";
import { getUserInfo, logout } from "../../redux/actions/userActions";
import { siteSettingsGetAll } from "../../redux/actions/siteSettingsAction";
import images from "../../images";

const Navbar = () => {
  const user = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const actions = {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    logout: bindActionCreators(logout, dispatch),
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  useEffect(() => {
    actions.getUserInfo();
    actions.getSiteSettings();
  }, []);

  const [isActiveNavbar, setIsActiveNavbar] = useState(false);

  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  return (
    <nav className={styles["navbar"]}>
      <div className="container">
        <div className={styles["navbar__nav"]}>
          <div className={styles["navbar__nav__links"]}>
            <Link to={"/"} className={styles["navbar__nav__links__link"]}>Anasayfa</Link>
            <Link to={"/companies"} className={styles["navbar__nav__links__link"]}>Nakliyat Firmaları</Link>
            <Link to={"/adverts"} className={styles["navbar__nav__links__link"]}>Yük İlanları</Link>
            <div className={styles["navbar__nav__links__auth"]}>
              <Link to={"/auth"} className={styles["navbar__nav__links__auth__register-btn"]}> <img className={styles["navbar__nav__links__btn-icon"]} src={images.register} /> Kayıt Ol</Link>
              <Link to={"/auth"} className={styles["navbar__nav__links__auth__login-btn"]}> <img className={styles["navbar__nav__links__btn-icon"]} src={images.login} /> Giriş Yap</Link>
            </div>
            <Link to={"/advert/add"} className={styles["navbar__nav__links__add-advert-btn"]}>Ücretsiz İlan Ver</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
