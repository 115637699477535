import * as types from '../actionTypes/companyaAdsTypes';
import * as initialState from '../initialStates';

export default (state = initialState.companyAds, { type, payload }) => {
  switch (type) {
    case types.GET_ACTİVE_MY_ADS:
      return { ...state, ads: payload };
    case types.GET_ACTİVE_MY_ADS_FAIL:
      return { error: payload };
    case types.CREATE_COMPANY_ADS:
      return { ...state, create: payload };
    case types.CREATE_COMPANY_ADS_FAIL:
      return { ...state, create: payload };
    case types.GET_MY_COMPANİES_FOR_ADS:
      return { ...state, companiesForCreate: payload };
    case types.GET_MY_COMPANİES_FOR_ADS_FAIL:
      return { error: payload };
    case types.GET_COMPANY_ADSES_RANDOM:
      return { ...state, companyAds: payload };

    default:
      return state;
  }
};
