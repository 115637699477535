import * as types from '../actionTypes/ForgotPasswordTypes';
import * as initialState from '../initialStates';

export default (state = initialState.userList, { type, payload }) => {
  switch (type) {
    case types.FORGOT_PASSWORD_SUCCESS:
      return payload;

    case types.FORGOT_PASSWORD_ACCESS_SUCCESS:
      return payload;
    default:
      return state;
  }
};
