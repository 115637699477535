import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import styles from './DeleteCompany.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { companyDelete, companyGetById } from '../../../redux/actions/companyActions';
import { getUserInfo } from '../../../redux/actions/userActions';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

const DeleteCompany = () => {
  const { id } = useParams();

  const [isRedirect, setIsRedirect] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const company = useSelector((state) => state.companyGetByReducer);
  const actions = {
    deleteCompany: bindActionCreators(companyDelete, dispatch),
    companyGetById: bindActionCreators(companyGetById, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  };

  useEffect(() => {
    actions.companyGetById(id);
    actions.getUserInfo();
  }, []);

  useEffect(() => {
    if (company.userId && user.id && company.userId != user.id && !user.isAdmin) {
      setIsRedirect(true);
    }
  }, [user, company]);

  const deleteHandler = (id) => {
    actions.deleteCompany(id);
  };

  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  return (
    <div className={styles['page']}>
      <Helmet>
        <title>
          {'Firmanı Kaldır - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
          }
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <div className={styles['delete-box']}>
        <p>"{id}" Id'sine sahip olan firmanızı silmek istediğinize emin misiniz ?</p>
        <Link to={'/myProfile'} onClick={() => deleteHandler(id)} className={styles.ok}>
          Evet Silmek İstiyorum
        </Link>
        <Link to={'/myProfile'} className={styles.cancel}>
          İptal Etmek istiyorum
        </Link>

        {isRedirect && <Navigate to={'/'} />}
      </div>
    </div>
  );
};

export default DeleteCompany;
