import React, { useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import styles from './Profile.module.scss';
import ProfileCard from './modules/profile-card/ProfileCard';
import CompanyList from './modules/company-list/CompanyList';
import AdvertList from './modules/advert-list/AdvertList';
import { getUserInfo } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { siteSettingsGetAll } from '../../redux/actions/siteSettingsAction';
import { companyGetMy } from '../../redux/actions/companyActions';
import { advertGetMy } from '../../redux/actions/advertActions';

const Profile = () => {
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem('token'));
  const actions = {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
    getMyCompanies: bindActionCreators(companyGetMy, dispatch),
    getMyAdrverts: bindActionCreators(advertGetMy, dispatch),
  };

  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const myAdverts = useSelector((state) => state.advertGetByReducer);
  const myCompanies = useSelector((state) => state.myCompaniesReducer);
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    actions.getSiteSettings();
    if (!user.id) {
      actions.getUserInfo();
    }
    if (user.id) {
      actions.getMyAdrverts(user.id);
      actions.getMyCompanies(user.id);
    }
  }, [user.id]);

  const [activeBarItem, setActiveBarItem] = useState('advert');

  return (
    <div className={styles['profile']}>
      <Helmet>
        <title>
          {'Profil Sayfası - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>

      <ProfileCard />
      <div className={styles['profile__lists']}>
        <div className={styles['profile__lists__bar']}>
          <button
            onClick={() => setActiveBarItem('company')}
            active={activeBarItem == 'company' ? 'true' : ''}
            className={styles['profile__lists__bar__item']}>
            Firmalarım ({myCompanies?.length || 0})
          </button>
          <button
            onClick={() => setActiveBarItem('advert')}
            active={activeBarItem == 'advert' ? 'true' : ''}
            className={styles['profile__lists__bar__item']}>
            İlanlarım ({myAdverts?.length || 0})
            <div>
        </div>
          </button>
        </div>
        {activeBarItem == 'advert' && (
          <div>
            {myAdverts?.length ? (
              <AdvertList />
            ) : (
              <div className={styles['add-new-button-box']}>
                <Link to={'/advert/add'} className={styles['add-new-button']}>
                  Hemen İlan Oluştur
                </Link>
              </div>
            )}
          </div>
        )}

        {activeBarItem == 'company' && (
          <div>
            {myCompanies?.length ? (
              <CompanyList />
            ) : (
              <div className={styles['add-new-button-box']}>
                <Link to={'/company/add'} className={styles['add-new-button']}>
                  Hemen Firma Oluştur
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      {!token && <Navigate to={'/'} />}
    </div>
  );
};

export default Profile;
