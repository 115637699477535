import * as actionTypes from "../actionTypes/companyRatingTypes";
import * as initialStates from "../initialStates";

export const companyRatingReducer = (
  state = initialStates.companyRatings,
  action
) => {
  switch (action.type) {
    case actionTypes.COMPANY_GET_ALL_RATINGS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};
