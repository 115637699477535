export const COMPANY_ADD_SUCCESS = "COMPANY_ADD_SUCCESS"

export const COMPANY_SUCCESS_SUCCESS = "COMPANY_SUCCESS_SUCCESS"

export const COMPANY_FAİLED_SUCCESS = "COMPANY_FAİLED_SUCCESS"

export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS"

export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS"

export const COMPANY_GETALL_SUCCESS = "COMPANY_GETALL_SUCCESS"
export const COMPANY_GET_BY_ID_SUCCESS = "COMPANY_GET_BY_ID_SUCCESS"
export const COMPANY_GET_BY_NAME_SUCCESS = "COMPANY_GET_BY_NAME_SUCCESS"
export const COMPANY_GET_MY_SUCCESS = "COMPANY_GET_MY_SUCCESS"
export const COMPANY_GET_AUTHOR_BY_ID = "COMPANY_GET_AUTHOR_BY_ID"


export const COMPANY_GET_PENDİNG_UPDATE_SUCCESS = "COMPANY_GET_PENDİNG_UPDATE_SUCCESS"
export const COMPANY_REQUEST_UPDATE_SUCCESS = "COMPANY_REQUEST_UPDATE_SUCCESS"
export const COMPANY_CONFİRM_PENDİNG_UPDATE_SUCCESS = "COMPANY_CONFİRM_PENDİNG_UPDATE_SUCCESS"
export const COMPANY_GET_BY_ID_PENDİNG_UPDATE_SUCCESS = "COMPANY_GET_BY_ID_PENDİNG_UPDATE_SUCCESS"