import React, { useState } from 'react';

import styles from '../styles.module.css';
import { bindActionCreators } from 'redux';
import { advertCreate } from '../../../redux/actions/advertActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import NoPermission from '../../../components/NoPermission/NoPermission';
import { useEffect } from 'react';
import { siteSettingsGetAll } from '../../../redux/actions/siteSettingsAction';
import { Helmet } from 'react-helmet';
import { phoneNumberSettings } from '../../../hooks/phoneNumberSettings';
import { categoryGetAll } from '../../../redux/actions/categoryActions';
import fileControl from '../../../hooks/fileControl';
import { getAllCitiesByFromWhereCountryId, getAllCitiesByToWhereCountryId, getAllFormDataCountries } from '../../../redux/actions/formDataGeographyActions';

const Addadvert = () => {
  const user = useSelector((state) => state.userReducer);
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const error = useSelector((state) => state.errorReducer)?.advertAdd;
  const categories = useSelector((state) => state.categoriesReducer);
  const geography = useSelector((state) => state.formDataGeography);

  const [fromWhereCountry,setFromWhereCountry] = useState("")
  const [toWhereCountry,setToWhereCountry] = useState("")

  const [fromWhereCity,setFromWhereCity] = useState("")
  const [toWhereCity,setToWhereCity] = useState("")



  const selectedFromWhereCountryHandle = (e)=>{
    const selectedValue = e.target[e.target.selectedIndex].innerText
    actions.getAllCitiesByFromWhereCountryId(e.target.value)
    setFromWhereCountry(selectedValue)
  }

  const selectedToWhereCountryHandle = (e)=>{
    const selectedValue = e.target[e.target.selectedIndex].innerText
    actions.getAllCitiesByToWhereCountryId(e.target.value)
    setToWhereCountry(selectedValue)
  }

  const selectedFromWhereCityHandle = (e)=>{
    const selectedValue = e.target[e.target.selectedIndex].innerText
    console.log(selectedValue)
    setFromWhereCity(selectedValue)
  }

  const selectedToWhereCityHandle = (e)=>{
    const selectedValue = e.target[e.target.selectedIndex].innerText
    setToWhereCity(selectedValue)
  }

  const dispatch = useDispatch();
  const actions = {
    addAdvert: bindActionCreators(advertCreate, dispatch),
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
    getAllCategories: bindActionCreators(categoryGetAll, dispatch),
    getAllCountries: bindActionCreators(getAllFormDataCountries, dispatch),
    getAllCitiesByFromWhereCountryId: bindActionCreators(getAllCitiesByFromWhereCountryId, dispatch),
    getAllCitiesByToWhereCountryId: bindActionCreators(getAllCitiesByToWhereCountryId, dispatch),
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [base64SelectImage, setBase64SelectImage] = useState('');

  useEffect(() => {
    actions.getSiteSettings();
    actions.getAllCategories();
    actions.getAllCountries();
  }, []);

  const addadvertHandler = async (e) => {
    e.preventDefault();
    const form = document.querySelector('#addForm');
    let formData = new FormData(form);
    formData.set("fromWhere",fromWhereCountry)
    formData.set("fromWhichCity",fromWhereCity)
    formData.set("toWhere",toWhereCountry)
    formData.set("toWhichWhere",toWhereCity)

    actions.addAdvert(formData).then((result) => result && setIsSuccess(true));
  };


  console.log(geography)

  return (
    <div className={styles['page']}>
      <Helmet>
        <title>
          {'İlan Paylaş - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>

      {user ? (
        <form id='addForm' className={styles['form']} encType='multipart/form-data'>
          <h1 className={styles.title}>İlan Paylaş</h1>
          {error && (
            <div className={styles['errors']}>
              <p className={styles['error']}>{error}</p>
            </div>
          )}
          <div className={styles['form-control']}>
            <label htmlFor='title'>İlan Başlığı</label>
            <input
              onBlur={(e) =>
                e.target.value.length > 0
                  ? e.target.previousElementSibling.classList.add('top')
                  : e.target.previousElementSibling.classList.remove('top')
              }
              onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
              type='text'
              id='title'
              name='title'
              required
            />
          </div>

          <div className={styles['form-control']}>
            <label htmlFor='phone'>Telefon Numarası</label>
            <input
              onChange={phoneNumberSettings}
              onBlur={(e) =>
                e.target.value.length > 0
                  ? e.target.previousElementSibling.classList.add('top')
                  : e.target.previousElementSibling.classList.remove('top')
              }
              onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
              type='text'
              id='phone'
              name='phone'
              required
            />
          </div>

          <div className={styles['two-section']}>
            <div className={styles['form-control']}>
              <label htmlFor='fromWhere'>Nereden</label>
              <select
                onBlur={(e) =>
                  e.target.value.length > 0
                    ? e.target.previousElementSibling.classList.add('top')
                    : e.target.previousElementSibling.classList.remove('top')
                }
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                defaultValue='0'
                type='number'
                id='fromWhere'
                name='fromWhere'
                onChange={selectedFromWhereCountryHandle}
                required>
                <option value='0' disabled></option>
                {geography.countries.map((country, i) => (
                  <option key={i} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles['form-control']}>
              <label htmlFor='fromWhichCity'>Neresinden</label>
              <select
                onBlur={(e) =>
                  e.target.value.length > 0
                    ? e.target.previousElementSibling.classList.add('top')
                    : e.target.previousElementSibling.classList.remove('top')
                }
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                defaultValue=''
                id='fromWhichCity'
                name='fromWhichCity'
                onChange={selectedFromWhereCityHandle}
                required>
                <option value='' disabled></option>
                {geography.fromCities.map((country, i) => (
                  <option key={i} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles['two-section']}>
            <div className={styles['form-control']}>
              <label htmlFor='toWhere'>Nereye</label>
              <select
                onBlur={(e) =>
                  e.target.value.length > 0
                    ? e.target.previousElementSibling.classList.add('top')
                    : e.target.previousElementSibling.classList.remove('top')
                }
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                defaultValue=''
                id='toWhere'
                name='toWhere'
                onChange={selectedToWhereCountryHandle}
                required>
                <option value='' disabled></option>
                {geography.countries.map((country, i) => (
                  <option key={i} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles['form-control']}>
              <label htmlFor='toWhichWhere'>Neresine</label>
              <select
                onBlur={(e) =>
                  e.target.value.length > 0
                    ? e.target.previousElementSibling.classList.add('top')
                    : e.target.previousElementSibling.classList.remove('top')
                }
                onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
                defaultValue=''
                id='toWhichWhere'
                name='toWhichWhere'
                onChange={selectedToWhereCityHandle}
                required>
                <option value='' disabled></option>
                {geography.toCities.map((country, i) => (
                  <option key={i} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles['form-control']}>
            <label htmlFor='productType'>Yük Tipi (Örneğin: Ev Eşyası)</label>
            <input
              onBlur={(e) =>
                e.target.value.length > 0
                  ? e.target.previousElementSibling.classList.add('top')
                  : e.target.previousElementSibling.classList.remove('top')
              }
              onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
              type='text'
              id='productType'
              name='productType'
              required
            />
          </div>

          <div className={styles['form-control']}>
            <label htmlFor='weight'>Ağırlık Giriniz [1000 KG]</label>
            <input
              maxLength={9999999999}
              min={0}
              type='number'
              onBlur={(e) =>
                e.target.value.length > 0
                  ? e.target.previousElementSibling.classList.add('top')
                  : e.target.previousElementSibling.classList.remove('top')
              }
              onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
              id='weight'
              name='weight'
              required
            />
          </div>

          <div className={styles['form-control']}>
            <label htmlFor='offerPrice'>Fiyat Teklifi (TL)</label>
            <input
              type='number'
              min='0'
              onBlur={(e) =>
                e.target.value.length > 0
                  ? e.target.previousElementSibling.classList.add('top')
                  : e.target.previousElementSibling.classList.remove('top')
              }
              onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
              id='offerPrice'
              name='offerPrice'
              required
            />
          </div>

          <div className={styles['form-control']}>
            <label htmlFor='category'>Kategori seç</label>
            <select
              onBlur={(e) =>
                e.target.value.length > 0
                  ? e.target.previousElementSibling.classList.add('top')
                  : e.target.previousElementSibling.classList.remove('top')
              }
              onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
              name='categoryId'
              defaultValue=''>
              <option value={''} disabled></option>

              {categories?.map((category, i) => (
                <option key={i} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className={styles['form-control']}>
            <label htmlFor='description'>İlan Açıklaması</label>
            <textarea
              rows={10}
              onBlur={(e) =>
                e.target.value.length > 0
                  ? e.target.previousElementSibling.classList.add('top')
                  : e.target.previousElementSibling.classList.remove('top')
              }
              onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
              type='text'
              id='description'
              name='description'
              required></textarea>
          </div>

          <div className={styles['form-control']}>
            <label className={styles['files-label']} htmlFor='image'>
              İlan Fotoğrafı (Zorunlu Değil)
            </label>
            <input
              onChange={(e) => fileControl(e, setBase64SelectImage)}
              style={{ opacity: 0, height: 0 }}
              type='file'
              id='image'
              name='image'
            />
            {base64SelectImage && <img src={base64SelectImage} width={150} height={100} />}
          </div>

          <input type='hidden' name='userId' value={user.id} />

          <Link onClick={addadvertHandler} className={styles['addBtn']}>
            İlan Paylaş
          </Link>
        </form>
      ) : (
        <NoPermission />
      )}

      {isSuccess && <Navigate to={'/myProfile'} />}
    </div>
  );
};

export default Addadvert;
