import React, { useEffect, useState } from 'react';

import styles from './ProfileCard.module.css';

import img from '../../../../images/no.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { advertGetMy } from '../../../../redux/actions/advertActions';
import { ChangeProfileImage, getUserInfo } from '../../../../redux/actions/userActions';
import fileControl from '../../../../hooks/fileControl';

const ProfileCard = () => {
  const user = useSelector((state) => state.userReducer);
  var [base64SelectImage, setBase64SelectImage] = useState();
  const dispatch = useDispatch();
  const actions = {
    getMyAdverts: bindActionCreators(advertGetMy, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    changeUserProfileImage: bindActionCreators(ChangeProfileImage, dispatch),
  };

  useEffect(() => {
    actions.getUserInfo();
  }, []);

  const changeImage = (e) => {
    var result = fileControl(e, setBase64SelectImage);
    if (result) {
      const form = document.querySelector('#changeImageForm');
      const formData = new FormData(form);
      actions.changeUserProfileImage(formData);
    }
  };

  return (
    <div className={styles.profilecard}>
      <div className={styles['profile-photo']}>
        <img className={styles.profileimg} src={base64SelectImage || user.imageUrl || img} />
        <form
          className={styles['profile-settings']}
          id='changeImageForm'
          encType='multipart/form-data'>
          <div>
            <label htmlFor='img' className={styles.changephoto}>
              Düzenle
            </label>
            <input onChange={changeImage} type='file' name='imageFile' hidden id='img' />

            <input type='hidden' name='userId' value={user.id} />
            <input type='hidden' name='oldServerPath' value={user.serverPath} />
            <input type='hidden' name='oldImagePath' value={user.imageUrl} />
          </div>
        </form>
      </div>
      <div className={styles['profile-info']}>
        <header className={styles.info}>
          <span>
            <i className='fa-solid fa-user'></i> {user.username}
          </span>
          <span>
            <i className='fa-solid fa-phone'></i> {user.phone}
          </span>
          <span>
            <i className='fa-solid fa-envelope'></i> {user.email}
          </span>
        </header>
      </div>
    </div>
  );
};

export default ProfileCard;
