import * as types from '../actionTypes/categoryTypes'
import * as initialState from '../initialStates'

export default (state = initialState.categoryState, { type, payload }) => {
    switch (type) {
        case types.CATEGORY_GET_BY_ID_SUCCESS:
            return payload
        default:
            return state
    }
}
