import * as types from '../actionTypes/formDataGeography';
import * as initialState from '../initialStates';

export default (state = initialState.geographyState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_COUNTRIES_SUCCESS:
      return { ...state, countries: payload };

    case types.GET_ALL_CITIES_BY_FROM_WHERE_COUNTRY_ID_SUCCESS:
      return { ...state, fromCities: payload };

    case types.GET_ALL_CITIES_BY_TO_WHERE_COUNTRY_ID_SUCCESS:
      return { ...state, toCities: payload };

    case types.GET_ALL_TOWNS_BY_FROM_WHERE_CITY_ID_SUCCESS:
      return { ...state, fromTowns: payload };

    case types.GET_ALL_TOWNS_BY_TO_WHERE_CITY_ID_SUCCESS:
      return { ...state, toTowns: payload };
    default:
      return state;
  }
};
