import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const PrivaciyPolicy = () => {
  return (
    <body className={styles['privacyPolicyContainer']}>
      <h1>Gizlilik Politikası</h1>

      <p>
        Veri Sorumlusu olarak, Nakliyecim.net olarak, kişisel verilerinizi toplarken ve işlerken
        KVKK'ya uygun olarak hareket etmekteyiz. Veri koruma taahhüdümüzü ciddiye alıyoruz.
      </p>

      <h2>Toplanan Kişisel Veriler</h2>

      <p>
        Nakliyecim.net üzerinde ilanları görüntülemek, ilan vermek, hesap oluşturmak ve iletişim
        kurmak amacıyla ad, soyad, adres, telefon numarası, e-posta, kullanıcı adı, şifre gibi
        kişisel verilerinizi toplamaktayız. Ayrıca, sitemizi ziyaret ettiğinizde otomatik olarak IP
        adresi, tarayıcı bilgisi, coğrafi konum bilgisi gibi teknik veriler de toplanmaktadır.
      </p>

      <h2>Veri İşleme Amaçları ve Hukuki Sebepler</h2>

      <p>
        Kişisel verilerinizi, ilanların yönetimi, iletişim, hesap oluşturma ve güvenlik amacıyla
        işlemekteyiz. Bu işlemler, sözleşmenin ifası, yasal yükümlülüklerin yerine getirilmesi ve
        meşru menfaatlerimizin korunması gibi hukuki sebeplere dayanmaktadır.
      </p>

      <h2>Veri Saklama Süreleri</h2>

      <p>
        Kişisel verilerinizi, hesabınız aktif olduğu sürece saklamaktayız. Hesabınızı silmeniz
        durumunda verileriniz silinecektir. Ayrıca, yasal saklama süreleri ve meşru menfaatlerimizin
        korunması gerekliliği doğrultusunda verilerinizi belirli bir süre saklayabiliriz.
      </p>

      <h2>Veri Güvenliği</h2>

      <p>
        Kişisel verilerinizi korumak için gerekli teknik ve idari güvenlik önlemlerini almaktayız.
        Veri sızıntılarına, kayıplara ve kötüye kullanıma karşı önlemlerimizi düzenli olarak gözden
        geçiriyor ve güncelliyoruz.
      </p>

      <h2>Veri Sahibi Hakları</h2>

      <p>
        KVKK kapsamında, kişisel verilerinize ilişkin olarak bilgi alma, veri düzeltme, silme,
        işleme faaliyetlerine itiraz etme gibi haklara sahipsiniz. Bu haklarınızı kullanmak için
        bize <a href='mailto:info@nakliyecim.net'>info@nakliyecim.net</a> adresinden
        ulaşabilirsiniz.
      </p>

      <h2>İletişim Bilgileri</h2>

      <p>
        KVKK Metni ile ilgili her türlü soru, görüş ve talepleriniz için bize{' '}
        <a href='mailto:info@nakliyecim.net'>info@nakliyecim.net</a> adresinden ulaşabilirsiniz.
      </p>

      <p>Şirket İsmi: Nakliyecim.net</p>
      <p>Şirket Sahibi: Murat Akyol</p>

      <footer>
        <p>© 2024 Nakliyecim.net - Tüm hakları saklıdır.</p>
      </footer>
    </body>
  );
};

export default PrivaciyPolicy;
