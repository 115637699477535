import React, { useEffect, useState } from 'react';

import styles from './CompanyList.module.css';

import img from '../../../../images/no.jpg';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { companyGetAll, companyGetMy } from '../../../../redux/actions/companyActions';
import { getUserInfo } from '../../../../redux/actions/userActions';
import Pagenations from '../../../../components/pagenations';

const CompanyList = () => {
  const myCompanies = useSelector((state) => state.myCompaniesReducer) || [];
  const user = useSelector((state) => state.userReducer) || {};
  const dispatch = useDispatch();
  const actions = {
    getMyCompanies: bindActionCreators(companyGetMy, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  };

  useEffect(() => {
    actions.getUserInfo();
  }, []);

  useEffect(() => {
    if (user.id) {
      actions.getMyCompanies(user.id);
    }
  }, [user]);

  const openPaymentPage = () => {
    // Ödeme sayfasını yeni bir pencerede aç
    window.open('http://localhost:3000/company/ads', '_blank', 'width=600,height=400');
  };

  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <div className={styles['company-list']}>
      <div className={styles.header}>
        <h1 className={styles.title}>Firmalarınız</h1>{' '}
        <div>
          <Link to={'/ads'} className={styles.adsBtn}>
            Reklam Oluştur
          </Link>
          <Link to={'/company/add'} className={styles.addCompanyBtn}>
            Firmenızı Ekleyin
          </Link>
        </div>
      </div>
      <table>
        <thead>
          <tr className={styles['company-titles']}>
            <th>Resim</th>
            <th>Şirket Adı</th>
            <th>Kontroller</th>
          </tr>
        </thead>
        <tbody>
          {myCompanies.map &&
            myCompanies.slice(startIndex, endIndex).map((company, i) => (
              <tr key={i}>
                <td>
                  <img className={styles.img} src={company.image || img} alt='' />
                </td>
                <td>
                  <h4 className={styles['company-name']}>
                    {company.name}
                    <span className={styles['company-status']}>
                      {company.isFailed ? (
                        <span className={styles['company-status__fail']}>
                          Onaylanmadı: {company.failedMsg}
                        </span>
                      ) : (
                        <>
                          {(company.isSuccess == false && company.isUpdate == false && (
                            <span className={styles['company-status__wait']}>
                              Yayın Onayı Bekleniyor
                            </span>
                          )) ||
                            (company.isSuccess == false && company.isUpdate == true && (
                              <span className={styles['company-status__wait']}>
                                Güncelleme Onayı Bekleniyor
                              </span>
                            )) || <span className={styles['company-status__success']}>Onaylı</span>}
                        </>
                      )}
                    </span>
                  </h4>
                </td>
                <td>
                  <div className={styles['controls']}>
                    {((company.isSuccess == true && company.isUpdate == false) ||
                      company.isFailed) && (
                      <>
                        <Link to={'/company/edit/' + company.id}>
                          <button className={styles['edit-btn']}>Düzenle</button>
                        </Link>

                        <Link to={'/company/delete/' + company.id}>
                          <button className={styles['delete-btn']}>Kaldır</button>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Pagenations
        setStart={setStartIndex}
        setEnd={setEndIndex}
        pageArray={myCompanies}
        showElementLength={5}
        scrollTo={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default CompanyList;
