import * as types from '../actionTypes/formDataGeography';
import { myAxios } from '../myAxios';

export const getAllCountriesSuccess = (data) => ({
  type: types.GET_ALL_COUNTRIES_SUCCESS,
  payload: data,
});

export const getAllFormDataCountries = () => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography');
    var data = await response.data;
    dispatch(getAllCountriesSuccess(data.data));
  };
};



export const getAllCitiesByFromWhereCountryIdSuccess = (data) => ({
  type: types.GET_ALL_CITIES_BY_FROM_WHERE_COUNTRY_ID_SUCCESS,
  payload: data,
});

export const getAllCitiesByFromWhereCountryId = (countryId) => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography/city/' + countryId);
    var data = await response.data;
    dispatch(getAllCitiesByFromWhereCountryIdSuccess(data.data));
  };
};

export const getAllCitiesByToWhereCountryIdSuccess = (data) => ({
  type: types.GET_ALL_CITIES_BY_TO_WHERE_COUNTRY_ID_SUCCESS,
  payload: data,
});

export const getAllCitiesByToWhereCountryId = (countryId) => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography/city/' + countryId);
    var data = await response.data;
    dispatch(getAllCitiesByToWhereCountryIdSuccess(data.data));
  };
};




export const getAllTonwsByFromWhereCityIdSuccess = (data) => ({
  type: types.GET_ALL_TOWNS_BY_FROM_WHERE_CITY_ID_SUCCESS,
  payload: data,
});

export const getAllTonwsByFromWhereCityId = (cityId) => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography/town/' + cityId);
    var data = await response.data;
    dispatch(getAllTonwsByFromWhereCityIdSuccess(data.data));
  };
};

export const getAllTonwsByToWhereCityIdSuccess = (data) => ({
  type: types.GET_ALL_TOWNS_BY_TO_WHERE_CITY_ID_SUCCESS,
  payload: data,
});

export const getAllTonwsByToWhereCityId = (cityId) => {
  return async (dispatch) => {
    var response = await myAxios().get('api/geography/town/' + cityId);
    var data = await response.data;
    dispatch(getAllTonwsByToWhereCityIdSuccess(data.data));
  };
};
