import * as types from '../actionTypes/siteSettingsTypes'
import * as initialState from '../initialStates'

export default (state = initialState.myCompanies, { type, payload }) => {
    switch (type) {

        case types.SITE_SETTINGS_GET_ALL_SUCCESS:
            return payload

        case types.SITE_SETTINGS_UPDATE_SUCCESS:
            return payload

        default:
            return state
    }
}
