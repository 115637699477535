import React from 'react';

import styles from './AdminNavbar.module.css';
import img from '../../../images/no.jpg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logout from '../../../components/logout/Logout';

const AdminNavbar = () => {
  const user = useSelector((state) => state.userReducer);

  return (
    <nav className={styles['admin-navbar']}>
      <h1 className={styles.title}>Admin Kontrol Paneli</h1>

      {/* Admin Profil Bölümü */}
      <div className={styles['admin-profile']}>
        <img className={styles['admin-profile-img']} src={user.imageUrl || img} alt='' />
        <p className={styles['admin-fullname']}>
          <i className='fa-solid fa-user'></i> {user.fullname}
        </p>
      </div>

      {/* Admin Linkleri */}
      <div className={styles['admin-nav']}>
        <Link to='/admin' className={styles['admin-link']}>
          <i className='fa-solid fa-home'></i> Anasayfa
        </Link>
        <Link to='/admin/company-settings' className={styles['admin-link']}>
          <i className='fa-solid fa-building'></i> Firma Ayarları
        </Link>
        <Link to='/admin/advert-settings' className={styles['admin-link']}>
          <i className='fa-solid fa-rectangle-ad'></i> İlan Ayarları
        </Link>
        <Link to='/admin/users' className={styles['admin-link']}>
          <i className='fa-solid fa-users'></i> Kullanıcılar
        </Link>
        <Link to='/admin/advert-ads' className={styles['admin-link']}>
          <i className='fa-solid fa-headset'></i> İlan Reklam Ayarları
        </Link>
        <Link to='/admin/company-ads' className={styles['admin-link']}>
          <i className='fa-solid fa-headset'></i> Firma Reklam Ayarları
        </Link>
        <Link to='/' className={styles['admin-link']}>
          <i className='fa-solid fa-home'></i> Kullanıcı Anasayfası
        </Link>
        <Link to='/myprofile' className={styles['admin-link']}>
          <i className='fa-solid fa-user'></i> Kullanıcı Profili
        </Link>
        <Link className={styles['admin-link']}>
          <i className='fa-solid fa-q'></i> <Logout />
        </Link>
      </div>
    </nav>
  );
};

export default AdminNavbar;
