import styles from './SponsorsSliders.module.scss'
import images from '../../images'
import { useEffect, useRef, useState } from 'react'
const SponsorsSlider = () => {

    const slideImages = [
        images.SponsorsSlideTest,
        images.SponsorsSlideTest,
        images.SponsorsSlideTest,
        images.SponsorsSlideTest,
    ]

    const [isMouseDown,setIsMouseDown] = useState(false)
    const [firstPageX,setFirstPageX] = useState(0)
    const [currentPageX,setCurrentPageX] = useState(0)

    const slidesRef = useRef()

    const onMouseDownHandle = (e)=>{
        setIsMouseDown(true)
        setFirstPageX(e.offsetX)
    }

    const onMouseMoveHandle = (e)=>{
        if(isMouseDown) {
            setCurrentPageX(currentPageX+e.movementX)
        }
    }

    const onMouseUpHandle = (e)=>{
        if(isMouseDown) {
            setIsMouseDown(false)                      
        }
    }

    const nextIndex = ()=>{
        const slides = slidesRef.current
        const slidesChildren = slides.children
        const firstSlide = slides.children[0]
        const slideWidth = firstSlide.offsetWidth
        const totalPageX = -slideWidth*(slidesChildren.length-1)
        const currentIndex = Math.round(currentPageX/slideWidth)
        const newCurrentPageX = currentPageX-slideWidth    
        if(Math.abs(newCurrentPageX)>=Math.abs(totalPageX)){
            setCurrentPageX(totalPageX)
        }  else if(newCurrentPageX>0) {
            setCurrentPageX(0)
        }else {
            setCurrentPageX(newCurrentPageX)
        }
    }

    const prevIndex = ()=>{
        const slides = slidesRef.current
        const slidesChildren = slides.children
        const firstSlide = slides.children[0]
        const slideWidth = firstSlide.offsetWidth
        const totalPageX = -slideWidth*(slidesChildren.length-1)
        const currentIndex = Math.round(currentPageX/slideWidth)
        const newCurrentPageX = currentPageX+slideWidth    
        if(Math.abs(newCurrentPageX)>=Math.abs(totalPageX)){
            setCurrentPageX(totalPageX)
        }  else if(newCurrentPageX>0) {
            setCurrentPageX(0)
        }else {
            setCurrentPageX(newCurrentPageX)
        }
    }

    useEffect(()=>{
        if(!isMouseDown){
            const slides = slidesRef.current
            const slidesChildren = slides.children
            const firstSlide = slides.children[0]
            const slideWidth = firstSlide.offsetWidth
            const totalPageX = -slideWidth*(slidesChildren.length-1)
            const currentIndex = Math.round(currentPageX/slideWidth)
            const newCurrentPageX = currentIndex*slideWidth    
            if(Math.abs(newCurrentPageX)>=Math.abs(totalPageX)){
                setCurrentPageX(totalPageX)
            }  else if(newCurrentPageX>0) {
                setCurrentPageX(0)
            }else {
                setCurrentPageX(newCurrentPageX)
            }
        }
    },[isMouseDown])

    

  return (
    <div className={styles["sponsors-slider"]}>
      <img onClick={prevIndex} className={styles["sponsors-slider__control"]} src={images.SponsorsSliderLeft}/>
      <div 
      onMouseDown={onMouseDownHandle}
      onMouseMove={onMouseMoveHandle}
      onMouseUp={onMouseUpHandle}
      onMouseLeave={onMouseUpHandle}
      ref={slidesRef}
      className={styles["sponsors-slider__slides"]}>
        {slideImages.map((imageUrl,i)=>(
            <img style={{transform:`translateX(${currentPageX}px)`,transition:`${!isMouseDown?"all .3s":""}`}}  src={imageUrl} key={i} />
        ))}
      </div>
      <img onClick={nextIndex} className={styles["sponsors-slider__control"]} src={images.SponsorsSliderRight}/>
    </div>
  )
}

export default SponsorsSlider
