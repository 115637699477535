import React from 'react';

import styles from './Header.module.scss';

import Alert from './modules/header-top-alert';
import Slider from '../slider';
import SearchBox from '../search-box';

const Header = () => {
  return (
    <header className={styles.header}>
      <Slider/>
      <div className={styles["header__search-box"]}>
        <div className='container'>
          <SearchBox/>
        </div>
      </div>
    </header>
  );
};

export default Header;
