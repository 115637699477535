import { combineReducers } from 'redux';

import { advertGetByReducer } from './advertGetByReducer';
import { advertReducer } from './advertReducer';
import { companyReducer } from './companyReducer';
import { companyGetByReducer } from './companyGetByReducer';
import categoriesReducer from './categoriesReducer';
import currentCategory from './currentCategoryReducer';
import userReducer from './userReducer';
import advertAuthorReducer from './advertAuthorReducer';
import companyAuthorReducer from './companyAuthorReducer';
import myCompaniesReducer from './myCompaniesReducer';
import companiesPendingUpdateReducer from './companiesPendingUpdateReducer';
import { companyCrudReducer } from './companyCrudReducer';
import allUserReducer from './allUserReducer';
import { getByIdPendingUpdateReducer } from './getByIdPendingUpdateReducer';
import siteSettingsReducer from './siteSettingsReducer';
import { errorReducer } from './errorReducer';
import companyAdsReducer from './companyAdsReducer';
import { companyLikeReducer } from './companyLikeReducer';
import { companyRatingReducer } from './companyRatingReducer';
import { loadingStateReducer } from './loadingStateReducer';
import citiesAndTownsReducer from './citiesAndTownsReducer';
import getPaymentDataReducer from './getPaymentDataReducer';
import geographyReducer from './geographyReducer';
import notificationReducer from './notificationReducer';
import chatReducer from './chatReducer';
import notReadMessageLengthReducer from './notReadMessageLengthReducer';
import createChatReducer from './createChatReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import formDataGeographyReducer from './formDataGeographyReducer';


const rootReducers = combineReducers({
  formDataGeography:formDataGeographyReducer,
  forgotPasswordReducer,
  createChat: createChatReducer,
  notReadMessageLength: notReadMessageLengthReducer,
  chatReducer,
  notificationReducer,
  getPaymentDataReducer,
  geographyReducer,
  citiesAndTownsReducer,
  loadingStateReducer,
  companyRatingReducer,
  companyLikeReducer,
  companyAdsReducer,
  siteSettingsReducer,
  advertGetByReducer,
  advertReducer,
  companyReducer,
  companyGetByReducer,
  categoriesReducer,
  errorReducer,
  currentCategory,
  userReducer,
  advertAuthorReducer,
  companyAuthorReducer,
  myCompaniesReducer,
  companiesPendingUpdateReducer,
  companyCrudReducer,
  allUserReducer,
  getByIdPendingUpdateReducer,
});

export default rootReducers;
