import { Link } from 'react-router-dom'
import images from '../../images'
import styles from './Slider.module.scss'

const Slider = () => {
  return (
    <div className={styles["slider"]}>
            <img className={styles["slider__image"]} src={images.sliderImage} alt='slider image' />
            <div className={styles["slider__controller"]}>
                {/* <img src={images.leftArrow}  className={styles["slider__controller__button"]} />
                <img src={images.rightArrow}  className={styles["slider__controller__button"]} /> */}
            </div>
          <div id={styles["container"]} className='container'>
                <div className={styles["slider__content"]}>
                    <h1 className={styles["slider__content__title"]}> Bütün Nakliyat İlanları Artık <u>Bir Arada.</u></h1>
                    <p className={styles["slider__content__description"]}>Hemen kayıt olun. Tüm nakliyat ilanlarına anında erişin.
        İster firmanız için yük arayın, ister yükünüz için firma arayın.</p>

                    <div className={styles["slider__content__ref-buttons"]}>
                        <Link className={styles["slider__content__ref-buttons__register"]} to={"/auth"}>Kayıt Ol</Link>
                        <Link className={styles["slider__content__ref-buttons__our-services"]} to={"/auth"}>Hizmetlerimiz</Link>
                    </div>
              </div>
          </div>
    </div>
  )
}

export default Slider
