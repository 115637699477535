import React, { useState } from 'react';

import styles from './UserSettings.module.css';
import img from '../../../images/no.jpg';

import AdminList from '../../../components/admin-components/AdminList/AdminList';
import AdminListItem from '../../../components/admin-components/AdminListItem/AdminListItem';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userGetAll, userMakeAdmin } from '../../../redux/actions/userActions';
import { useEffect } from 'react';
import { loadingState } from '../../../redux/initialStates';
import { loadingStateChanged } from '../../../redux/actions/loadingStateActions';
import { Helmet } from 'react-helmet';
import { createNewChat, createNewChatReset } from '../../../redux/actions/chatActions';
import { Navigate } from 'react-router-dom';

const UserSettings = () => {
  const dispatch = useDispatch();
  const actions = {
    getAllUser: bindActionCreators(userGetAll, dispatch),
    makeAdmin: bindActionCreators(userMakeAdmin, dispatch),
    createNewChat: bindActionCreators(createNewChat, dispatch),
    createNewChatReset: bindActionCreators(createNewChatReset, dispatch),
  };

  const user = useSelector((state) => state.userReducer);
  const allUser = useSelector((state) => state.allUserReducer);
  const isLoadingState = useSelector((state) => state.loadingStateReducer);

  const isCreateChat = useSelector((state) => state.createChat);

  const [listData, setListData] = useState(allUser);
  const [isActiveDetail, setIsActiveDetail] = useState({
    isActive: false,
    currentDetails: {},
  });

  const detailsHandler = (userObj) => {
    setIsActiveDetail({
      isActive: true,
      currentDetails: userObj,
    });
  };

  const makeAdmin = (userId) => {
    actions.makeAdmin(userId);
  };

  useEffect(() => {
    actions.getAllUser();
    return () => {
      actions.createNewChatReset();
    };
  }, []);

  useEffect(() => {
    if (isLoadingState.userMakeAdmin === 'ok') {
      actions.getAllUser();
      dispatch(loadingStateChanged({ userMakeAdmin: false }));
    }
  }, [isLoadingState]);

  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  return (
    <div>
      <Helmet>
        <title>
          {'Kullanıcı Ayarları - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <AdminList
        title='Adminler'
        theads={['#ID', 'Profile Resmi', 'Ad & Soyad', 'Kullanıcı Adı', 'Email', 'Kontroller']}>
        {allUser
          .filter((data) => data.isAdmin && data.id != user.id)
          .map((data, i) => (
            <AdminListItem
              key={i}
              items={[
                data.id,
                <img width={100} src={data.imageUrl || img} />,
                data.fullname,
                data.username,
                data.email,
                <div className={styles.kontroller}>
                  <button className='company-details-btn' onClick={() => makeAdmin(data.id)}>
                    Admini Çıkar
                  </button>
                  <button className='company-details-btn' onClick={() => detailsHandler(data)}>
                    Detaylar
                  </button>
                </div>,
              ]}
            />
          ))}
      </AdminList>

      <AdminList
        title='Kullanıcılar'
        theads={['#ID', 'Profile Resmi', 'Ad & Soyad', 'Kullanıcı Adı', 'Email', 'Kontroller']}>
        {allUser
          .filter((data) => !data.isAdmin)
          .map((data, i) => (
            <AdminListItem
              key={i}
              items={[
                data.id,
                <img width={100} src={data.imageUrl || img} />,
                data.fullname,
                data.username,
                data.email,
                <div className={styles.kontroller}>
                  <button className='company-details-btn' onClick={() => makeAdmin(data.id)}>
                    Admin Yap
                  </button>
                  <button className='company-details-btn' onClick={() => detailsHandler(data)}>
                    Detaylar
                  </button>
                  <button
                    className='company-details-btn'
                    onClick={() => actions.createNewChat([user.id, data.id])}>
                    Mesaj Gönder
                  </button>
                </div>,
              ]}
            />
          ))}
      </AdminList>

      {isActiveDetail.isActive && (
        <>
          <div
            className='details-popup-after'
            onClick={() => setIsActiveDetail({ isActive: false })}></div>
          <div className='details-popup'>
            <h1 className='details-popup-control'>
              Detaylar <span onClick={() => setIsActiveDetail({ isActive: false })}>X</span>
            </h1>
            <div className='details'>
              <p>
                Kullanıcı ID: {isActiveDetail.currentDetails && isActiveDetail.currentDetails.id}
              </p>
              <p>
                Kullanıcı Adı:
                {isActiveDetail.currentDetails && isActiveDetail.currentDetails.username}
              </p>
              <p>
                Adres Bilgisi:{' '}
                {isActiveDetail.currentDetails && isActiveDetail.currentDetails.address}
              </p>
              <p>E-Posta: {isActiveDetail.currentDetails && isActiveDetail.currentDetails.email}</p>
              <p>
                Telefon Numarası:{' '}
                {isActiveDetail.currentDetails && isActiveDetail.currentDetails.phone}
              </p>
              <p>
                Posta Kodu:{' '}
                {isActiveDetail.currentDetails && isActiveDetail.currentDetails.postCode}
              </p>
              <p>
                Adı Ve Soyadı:{' '}
                {isActiveDetail.currentDetails && isActiveDetail.currentDetails.fullname}
              </p>
            </div>
          </div>
        </>
      )}

      {isCreateChat.length > 0 && <Navigate to={`/chats`} />}
    </div>
  );
};

export default UserSettings;
