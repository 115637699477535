import React from 'react'

import styles from './SiteSettings.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { siteSettingsGetAll, siteSettingsUpdate } from '../../../redux/actions/siteSettingsAction'
import { useEffect } from 'react'
import { useState } from 'react'


const SiteSettings = () => {




    const currentSettings = useSelector(state => state.siteSettingsReducer)
    const dispatch = useDispatch()
    const actions = {
        getSettings: bindActionCreators(siteSettingsGetAll, dispatch),
        updateSettings: bindActionCreators(siteSettingsUpdate, dispatch)
    }
    const [isAnnouncement, setIsAnnouncement] = useState(currentSettings.isAnnouncement)
    const [announcementType, setAnnouncementType] = useState(currentSettings.isAnnouncement)


    useEffect(() => {
        actions.getSettings()
    }, [])

    useEffect(() => {
        setIsAnnouncement(currentSettings.isAnnouncement)
    }, [currentSettings.isAnnouncement])


    useEffect(() => {
        setAnnouncementType(currentSettings.announcementType)
    }, [currentSettings.announcementType])


    const saveSiteSettings = (e) => {
        e.preventDefault()

        const form = new FormData(e.target)

        actions.updateSettings(form)
    }

    const fileControl = (e) => {
        var type = e.target.files[0].type
        var types = ["image/jpeg", "image/jpg", "image/gif", "image/png"]
        if (types.indexOf(type) == -1) {
            e.target.value = ""
            alert("lütfen jpeg,jpg,gif,png türlerinden herhangi birine sahip olan dosya uzantılarını yükleyin")
        }
    }
    return (
        <form onSubmit={saveSiteSettings} className={styles.settings}>
            <div className={styles.setting}>
                <div className={styles["from-froup"]}>
                    <label htmlFor="title">Başlığı</label>
                    <input type="text" id='title' defaultValue={currentSettings.title} name='title' />
                </div>
            </div>
            <div className={styles.setting}>
                <div className={styles["from-froup"]}>
                    <label htmlFor="description">Açıklaması</label>
                    <input type="text" id='description' defaultValue={currentSettings.description} name='description' />
                </div>
            </div>
            <div className={styles.setting}>
                <div className={styles["from-froup"]}>
                    <label htmlFor="author">Site Sahibi (Ad & Soyad)</label>
                    <input type="text" id='author' defaultValue={currentSettings.author} name='author' />
                </div>
            </div>
            <div className={styles.setting}>
                <div className={styles["from-froup"]}>defaultValue
                    <label htmlFor="keywords">Anahtar Kelimeler</label>
                    <input type="text" id='keywords' name='keywords' defaultValue={currentSettings.keywords} />
                </div>
            </div>
            <div className={styles.setting}>
                <div className={styles["from-froup"]}>
                    <label htmlFor="isAnnouncement">Duyuru Yayınlama</label>
                    <select onChange={e => setIsAnnouncement(e.target.value)} defaultValue={isAnnouncement} name="isAnnouncement" id="isAnnouncement">
                        <option value={true}>Aktif</option>
                        <option value={false}>Pasif</option>
                    </select>
                </div>
                <div className={styles["from-froup"]}>
                    <label htmlFor="announcementTitle">Duyuru Başlığı</label>
                    <input type="text" id='announcementTitle' defaultValue={currentSettings.announcementTitle} name='announcementTitle' />
                </div>
                <div className={styles["from-froup"]}>
                    <label htmlFor="announcementType">Duyuru Tipi</label>
                    <select placeholder='info,warning,danger değerlerini gire bilirsiniz.' onChange={e => setAnnouncementType(e.target.value)} value={announcementType} name="announcementType" id="announcementType">
                        <option value="info">Bilgi</option>
                        <option value="warning">Uyarı</option>
                        <option value="danger">Önemli Duyuru</option>
                    </select>
                </div>
                <div className={styles["from-froup"]}>
                    <label htmlFor="announcementText">Duyuru Açıklaması</label>
                    <input type="text" id='announcementText' defaultValue={currentSettings.announcementText} name='announcementText' />
                </div>
            </div>

            <div className={styles.setting}>
                <div className={styles["from-froup"]}>
                    <label className={styles["upload-file"]} htmlFor="logo">Logo Yükle</label>

                    <input onChange={fileControl} type="file" id='logo' name='logo' />
                </div>
                <img src={currentSettings.logoPath} width={100} alt="" />
            </div>


            <button className={styles.saveBtn}>Ayarları Kaydet</button>
            {/* <div>
                kübra galp murat
            </div> */}
        </form>


    )
}

export default SiteSettings
