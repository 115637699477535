export const phoneNumberSettings = (e) => {  
  if (e.target.value.startsWith("+9")) {
    e.target.value = e.target.value.slice(2);
  }

  if (e.target.value.startsWith("9")) {
    e.target.value = e.target.value.slice(2);
  }

  if (
    isNaN(e.target.value[e.target.value.length - 1]) ||
    e.target.value.length > 20
  ) {
    e.target.value = e.target.value.slice(0, e.target.value.length - 1);
  }
};
