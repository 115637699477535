import * as types from "../actionTypes/loadingStateActionTypes";
import * as initialState from "../initialStates";

export const loadingStateReducer = (
  state = initialState.loadingState,
  action
) => {
  switch (action.type) {
    case types.LOADING_STATE_CHANGED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
