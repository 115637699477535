import styles from './OurContact.module.scss'

const OurContact = () => {
  return (
    <div className={styles["our-contact"]}>
      <h6 className={styles["our-contact__title"]}>Bize Ulaşın</h6>
      <div className='container'>
        <form method='post' className={styles["our-contact__form"]}>
            <div className={styles["our-contact__form__elements"]}>
                <input required name='name' placeholder='Adınız*' className={styles["our-contact__form__elements__input"]} />
                <input required name='surname' placeholder='Soyadınız*' className={styles["our-contact__form__elements__input"]} />
                <input required name='email' type='email' placeholder='E-Posta Adresiniz*' className={styles["our-contact__form__elements__input"]} />
                <input name='phone' type='number' placeholder='Telefon' className={styles["our-contact__form__elements__input"]} />
                <textarea required name='message' placeholder='Mesajınız' rows={10} className={styles["our-contact__form__elements__input"]} data-full></textarea>
            </div>
            <button type='submit' className={styles["our-contact__form__button"]}>Gönder</button>
        </form>
      </div>
    </div>
  )
}

export default OurContact
