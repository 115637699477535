import { Link } from 'react-router-dom';
import images from '../../images';
import styles from './Chats.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewChatReset,
  getAllMyChats,
  messageAdd,
  messageRead,
} from '../../redux/actions/chatActions';
import { bindActionCreators } from 'redux';
import { useEffect, useState } from 'react';
import { getUserInfo } from '../../redux/actions/userActions';

const Chats = () => {
  const dispatch = useDispatch();
  const actions = {
    getAllMyChats: bindActionCreators(getAllMyChats, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    messageAdd: bindActionCreators(messageAdd, dispatch),
    messageRead: bindActionCreators(messageRead, dispatch),
    createNewChatReset: bindActionCreators(createNewChatReset, dispatch),
  };

  var chats = useSelector((state) => state.chatReducer);
  var user = useSelector((state) => state.userReducer);
  useEffect(() => {
    if (user.id) {
      actions.getAllMyChats(user.id);
    }
    actions.createNewChatReset();
  }, [user]);

  const chatsShorter = (chats) => {
    return chats.sort((a, b) => {
      if (
        a.messages.length > 0 &&
        b.messages.length > 0 &&
        a.users.length > 1 &&
        b.users.length > 1
      ) {
        let lastMessageA = new Date(a.messages[a.messages.length - 1]?.date);
        let lastMessageB = new Date(b.messages[b.messages.length - 1]?.date);

        return lastMessageB - lastMessageA;
      }
      return -1;
    });
  };

  const handleReadAllMessage = (chatId) => {
    actions.messageRead(user.id, chatId);
  };

  return (
    <div className={styles['chats']}>
      <header className={styles['chats__header']}>
        <h1>Mesajlar</h1>
      </header>
      {chats.length == 0 ? (
        <div className={styles['chats__no-message-box']}>
          <img src={images.message} />
          <h5>Hiç Mesajınız Yok</h5>
        </div>
      ) : (
        <div className={styles['chats__container']}>
          <section className={styles['chats__list']}>
            <h2>En Son</h2>

            {chatsShorter(chats).map((chat, i) => {
              var getterUser = chat.users.filter((x) => x.id != user.id)[0];
              var notReadMessageLength = chat.messages.filter(
                (x) => x.isRead == false && x.senderUserId != user.id,
              ).length;
              return (
                getterUser && (
                  <Link key={i} to={'/chats/' + chat.id}>
                    <div
                      onClick={() => {
                        notReadMessageLength > 0 && handleReadAllMessage(chat.id);
                      }}
                      key={i}
                      className={styles['chats__list__chat']}>
                      <img
                        title='resim yok'
                        src={getterUser.imageUrl || images.noImage}
                        className={styles['chats__list__chat__image']}
                      />
                      <div className={styles['chats__list__chat__content']}>
                        <h3 className={styles['chats__list__chat__content__title']}>
                          {getterUser.fullname} ({getterUser.username})
                        </h3>
                        {notReadMessageLength ? (
                          <b className={styles['chats__list__chat__content__not-read-message']}>
                            ({notReadMessageLength}) adet okunmamış mesaj
                          </b>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Link>
                )
              );
            })}
          </section>
        </div>
      )}
    </div>
  );
};

export default Chats;
