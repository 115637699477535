import * as types from "../actionTypes/loadingStateActionTypes";

const loadingStateChangedSuccess = (obj) => ({
  type: types.LOADING_STATE_CHANGED,
  payload: obj,
});

export const loadingStateChanged = (changedObj) => {
  return (dispatch) => {
    dispatch(loadingStateChangedSuccess(changedObj));
  };
};
