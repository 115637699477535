import loading from './loading.gif';
import trBayrak from './tr-bayrak.png';
import noImage from './no.jpg';
import notification from './notification.svg';
import message from './message.png';
import register from './register.svg';
import login from './login.svg';
import sliderImage from './slider-image.png';
import leftArrow from './left-arrow.svg';
import rightArrow from './right-arrow.svg';
import searchIcon from './search-icon.svg';
import microphoneIcon from './microphone-icon.svg';
import microphoneIconActive from './microphone-icon-active.svg';
import transportationRight from './transportation-right.svg';
import transportationLeft from './transportation-left.svg';

import diamondIcon from './diamond.svg';
import megaphoneIcon from './megaphone.svg';
import companiesIcon from './companies-icon.svg';
import icon_7_24 from './7-24.svg';
import sectionTransport from './section-transport.svg';
import trMap from './tr-map.png';
import sectionBracket from './bracket.svg';
import commentIcon from './comment-icon.png';
import signature from './signature.png';

import SponsorsSliderLeft from './sponsors-slider-left.png';
import SponsorsSliderRight from './sponsors-slider-right.png';

import SponsorsSlideTest from './sponsor-slide-test.svg';

import advantagesSectionBg from './advantages-bg.png';
import testPost from './test-post.png';
import MobileApp from './mobile-app-section.png';
import googlePlay from './google-play.png';
import appStore from './app-store.png';

import facebook from './facebook.svg';
import instagram from './instagram.svg';
import x_icon from './x.svg';
import linkedin from './linkedin.svg';




export default {
  facebook,
  instagram,
  linkedin,
  x_icon,
  googlePlay,
  appStore,
  MobileApp,
  testPost,
  advantagesSectionBg,
  SponsorsSlideTest,
  SponsorsSliderRight,
  SponsorsSliderLeft,
  signature,
  commentIcon,
  diamondIcon,
  sectionBracket,
  megaphoneIcon,
  companiesIcon,
  icon_7_24,
  sectionTransport,
  trMap,
  loading,
  trBayrak,
  noImage,
  notification,
  message,
  register,
  login,
  sliderImage,
  leftArrow,
  rightArrow,
  searchIcon,
  microphoneIcon,
  microphoneIconActive,
  transportationRight,
  transportationLeft,
};
