import React from 'react'

import styles from './Footer.module.scss'
import { Link } from 'react-router-dom'
import images from '../../images'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className='container'>
                <section className={styles["footer__top"]}>
                    <div className={styles["footer__top__section"]}>
                        <h6 className={styles["footer__top__section__title"]}>Yardıma mı İhtiyacınız Var?</h6>
                        <div className={styles["footer__top__section__contact"]}>
                            <div className={styles["footer__top__section__contact__item"]}>
                                <p className={styles["footer__top__section__contact__item__title"]}>Bizi Arayın</p>
                                <p className={styles["footer__top__section__contact__item__description"]}>+90 537 027 5188</p>
                            </div>

                            <div className={styles["footer__top__section__contact__item"]}>
                                <p className={styles["footer__top__section__contact__item__title"]}>Destek ekibimize yazın</p>
                                <p className={styles["footer__top__section__contact__item__description"]}>support@nakliyecim.net</p>
                            </div>

                            <div className={styles["footer__top__section__contact__item"]}>
                                <p className={styles["footer__top__section__contact__item__title"]}>Bizi takip edin</p>
                                <p className={styles["footer__top__section__contact__item__social-icons"]}>
                                    <Link to="/"><img className={styles["footer__top__section__contact__item__social-icons__icon"]} src={images.facebook} alt='facebook ikonu' /></Link>
                                    <Link to="/"><img className={styles["footer__top__section__contact__item__social-icons__icon"]} src={images.instagram} alt='instagram ikonu' /></Link>
                                    <Link to="/"><img className={styles["footer__top__section__contact__item__social-icons__icon"]} src={images.x_icon} alt='x ikonu' /></Link>
                                    <Link to="/"><img className={styles["footer__top__section__contact__item__social-icons__icon"]} src={images.linkedin} alt='linkedin ikonu' /></Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles["footer__top__section"]}>
                        <h6 className={styles["footer__top__section__title"]}>Kurumsal</h6>
                        <div className={styles["footer__top__section__links"]}>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>Hakkımızda</Link>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>İletişim</Link>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>Bize Katılın</Link>
                        </div>                    
                    </div>
                    <div className={styles["footer__top__section"]}>
                        <h6 className={styles["footer__top__section__title"]}>Destek</h6>
                        <div className={styles["footer__top__section__links"]}>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>S. S. S.</Link>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>İş birliği</Link>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>Bülten</Link>
                            <Link to={"/privacy"} className={styles["footer__top__section__links__link"]}>Gizlilik Politikası</Link>
                        </div>                    
                    </div>
                    <div className={styles["footer__top__section"]}>
                        <h6 className={styles["footer__top__section__title"]}>Hizmetler</h6>
                        <div className={styles["footer__top__section__links"]}>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>Nakliyeci+</Link>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>Reklamla öne çıkın</Link>
                            <Link to={"/"} className={styles["footer__top__section__links__link"]}>Planlar</Link>
                        </div>                    
                    </div>
                  
                </section>
            </div>
                <section className={styles["footer__bottom"]}>
                    <div className='container'>
                        <div  className={styles["footer__bottom__box"]}>
                            <p>Copyright &copy; 2024 Tüm hakları saklıdır</p> <h6>Nakliyecim.net</h6>
                        </div>
                    </div>
                </section>
        </footer>
    )
}

export default Footer
