import React, { useEffect, useState } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';

import styles from './AdminLaayout.module.css';

import AdminNavbar from '../views/AdminPages/AdminNavbar/AdminNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  companyGetAll,
  companyGetMy,
  companyGetPendingUpdate,
} from '../redux/actions/companyActions';
import { getUserInfo } from '../redux/actions/userActions';
import UseSignalr from '../hooks/useSignalr';

const AdminLayout = () => {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')));

  const user = useSelector((state) => state.userReducer);
  const isCompanyCrud = useSelector((state) => state.companyCrudReducer);
  const dispatch = useDispatch();
  const actions = {
    getAllCompany: bindActionCreators(companyGetAll, dispatch),
    getAllMyCompany: bindActionCreators(companyGetMy, dispatch),
    getAllPendingUpdateCompany: bindActionCreators(companyGetPendingUpdate, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  };

  useEffect(() => {
    actions.getUserInfo();
  }, [isCompanyCrud]);

  return (
    <>
      <>
        {user && user.isAdmin && (
          <div className={styles.adminlayout}>
            <AdminNavbar />
            <UseSignalr />

            <div className={styles.outlet}>
              <Outlet />
            </div>
          </div>
        )}
        {!token && <Navigate to={'/auth'} />}
      </>
    </>
  );
};

export default AdminLayout;
