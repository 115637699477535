export const filterHook = (data, filterData) => {
  const filterDataObjToArray = Object.entries(filterData);

  var result = data.filter((obj) => {
    const testResult = [];

    for (let i = 0; i < filterDataObjToArray.length; i++) {
      if (
        obj[filterDataObjToArray[i][0]] == filterDataObjToArray[i][1] ||
        filterDataObjToArray[i][1] == 0
      ) {
        testResult.push(true);
      }
    }

    return filterDataObjToArray.length == testResult.length;
  });

  return result;
};

export const removeFiltreItem = (filtreObj, deleteKeyName) => {
  var filtreObjToArray = Object.entries(filtreObj);
  var result = filtreObjToArray.filter((objItem) => objItem[0] != deleteKeyName);
  return Object.fromEntries(result);
};

export const addFiltreItem = (filtreObj, keyAndValueArray) => {
  var filtreObjToArray = Object.entries(filtreObj);
  filtreObjToArray.push(keyAndValueArray);

  return Object.fromEntries(filtreObjToArray);
};
