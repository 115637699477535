import * as types from '../actionTypes/categoryTypes';
import { myAxios } from '../myAxios';

// const data = [
//   {
//     id: 1,
//     name: "Şehir içi Nakliye",
//   },
//   {
//     id: 2,
//     name: "Şehirler arası Nakliye",
//   },
//   {
//     id: 3,
//     name: "Uluslar Arası Nakliye",
//   },
//   {
//     id: 4,
//     name: "Şehir içi ve Uluslar Arası Nakliye",
//   },
// ];

export const categoryGetAllSuccess = (data) => ({
  type: types.CATEGORY_GETALL_SUCCESS,
  payload: data,
});

export const categoryGetAll = () => {
  return async (dispatch) => {
    var res = await myAxios().get('/api/category');
    var data = await res.data;
    return dispatch(categoryGetAllSuccess(data.data));
  };
};

export const categoryGetByIdSuccess = (data) => ({
  type: types.CATEGORY_GET_BY_ID_SUCCESS,
  payload: data,
});

export const categoryGetById = (id) => {
  return async (dispatch) => {
    var res = await myAxios().get('/api/category/' + id);
    var data = await res.data;
    return dispatch(categoryGetByIdSuccess(data.data));
  };
};
