export const companyState = [];
export const companyGetByState = {};
export const companyRatings = [];
export const advertGetByState = {};
export const categoriesState = [];
export const chatsState = [];
export const createChatState = 0;
export const categoryState = {};
export const advertState = [];
export const notReadMessageLength = 0;
export const user = {};
export const userList = [];
export const forgotPassword = {};
export const myCompanies = [];
export const siteSettings = {};
export const errors = {};
export const companyAds = [];
export const paymentData = {};
export const geographyState = {
  countries: [],
  fromCities: [],
  fromTowns: [],
  toCities: [],
  toTowns: [],
};
export const loadingState = {
  // auth loadings
  auth: false, //okey
  userMakeAdmin: false, //okey
  getProfile: false,
  changeProfileImage: false,
  userGetAll: false,

  // company loadings
  companyList: false, //okey
  getMyCompanies: false, //okey
  companyAdded: false, //okey
  companyUpdate: false, //okey
  getByIdCompany: false, //okey
  companyDelete: false,
  getByCompanyUser: false, //okey

  // advert loading
  advertList: false, //okey
  advertAdded: false,
  advertUpdate: false,
  advertDelete: false,
  advertGetById: false,
  advertGetMy: false,
  getByAdvertUser: false,

  //advert ads
  advertAds: false,
  advertAdsAdded: false,

  //companyAds
  companyAds: false,
  companyAdsAdded: false,

  //company ratings
  getAllCompanyRatings: false,
  getAllCompanyLikes: false,
  companyLike: false,
  companyRating: false,
};
