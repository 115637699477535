import { bindActionCreators } from 'redux';
import styles from './Chat.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMyChats, messageRead, notReadEmailSend } from '../../redux/actions/chatActions';
import { useEffect, useRef, useState } from 'react';
import { sendMessage } from '../../redux/actions/chatActions';
import { useParams } from 'react-router-dom';
import images from '../../images';

const Chat = () => {
  const dispatch = useDispatch();
  const { id: chatId } = useParams();
  const actions = {
    getAllMyChats: bindActionCreators(getAllMyChats, dispatch),
    sendMessage: bindActionCreators(sendMessage, dispatch),
    messageRead: bindActionCreators(messageRead, dispatch),
    notReadEmailSend: bindActionCreators(notReadEmailSend, dispatch),
  };
  const messagesRef = useRef();
  const [messageText, setMessageText] = useState('');
  var chats = useSelector((state) => state.chatReducer);
  var [currentChat, setCurrentChat] = useState({});
  var [nowSendMessage, setNowSendMessage] = useState(0);
  var [notFound, setNotFound] = useState(false);
  var user = useSelector((state) => state.userReducer);
  var getterUser = currentChat?.users?.filter((x) => x.id != user.id)[0];
  useEffect(() => {
    messagesRef.current?.scrollTo(0, 99999999);
  }, [currentChat?.messages?.length]);

  useEffect(() => {
    if (chats.length > 0) {
      var chat = chats?.filter((x) => x.id == chatId)[0];
      if (!chat) {
        setNotFound(true);
      }
      setCurrentChat(chat);
    }
  }, [chats]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (user.id && messageText.trim()) {
      actions.sendMessage({
        msg: messageText,
        chatId,
        senderUserId: user.id,
      });
      setMessageText('');
      setNowSendMessage(1);
      messagesRef.current?.scrollTo(0, 99999999);
    }
  };

  useEffect(() => {
    const handleBeforeUnLoad =()=> {
      if (currentChat.id && user.id) {
        // email gönder
        if (nowSendMessage > 0) {
          actions.notReadEmailSend([user.id, getterUser.id], currentChat);
        }
      }
    }

    window.addEventListener("beforeunload",handleBeforeUnLoad)
    //sayfa terk edildiğinde çalışacak
    return () => {
      if (currentChat.id && user.id) {
        actions.messageRead(user.id, currentChat.id);
        // email gönder
        if (nowSendMessage > 0) {
          actions.notReadEmailSend([user.id, getterUser.id], currentChat);
        }
      }
    };
  }, [currentChat.id, user.id, nowSendMessage]);
  return (
    <>
      {currentChat.id && (
        <div className={styles['chat']}>
          <h1 className={styles['chat__username']}>
            <img
              className={styles['chat__username__image']}
              src={getterUser.imageUrl || images.noImage}
            />
            {getterUser?.username}
          </h1>
          <div ref={messagesRef} className={styles['chat__messages']}>
            {currentChat?.messages?.map((message, i) =>
              message.senderUserId == user.id ? (
                <div data-isMy={true} className={styles['chat__messages__message']}>
                  {message.msg}
                </div>
              ) : (
                <div className={styles['chat__messages__message']}>{message.msg}</div>
              ),
            )}
          </div>
          <form onSubmit={handleSendMessage} className={styles['chat__send-message']}>
            <input
              onChange={(e) => {
                setMessageText(e.target.value);
              }}
              value={messageText}
              className={styles['chat__send-message__input']}
              type='text'
              placeholder='Bir Mesaj Gönder'
            />
            <button type='submit' className={styles['chat__send-message__button']}>
              Gönder
            </button>
          </form>
        </div>
      )}

      {!currentChat.id && (
        <h1 className={styles['notfound-text']}>
          Bu Mesaj Sayfasına Ulaşılamıyor Silinmiş Olabilir.
        </h1>
      )}
    </>
  );
};

export default Chat;
